
import {throwError as observableThrowError,  forkJoin, of ,  Observable } from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { map, catchError, tap } from 'rxjs/operators';
import { Recording } from '@app/models/recording.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from './util.service';

import { ResponseSimple } from '@app/models/responses.model';

@Injectable()
export class RecordingService extends HttpService {
  reloadUserRecordings = new EventEmitter();
  getNewRecordings = new EventEmitter();
  userId: string;

  constructor(
    //private http: Http,
    private http2: HttpClient,
    private toastrService: ToastrService,
     private utilService: UtilService
  ) {
    super();
    this.reloadUserRecordings.subscribe(data => {
      if (data.userId) {
        this.userId = data.userId;
      } else {
        this.userId = '';
      }
    });
  }

  get(id) {
    const url = this.makeUrl('/recordings/' + id);
    const options = this.getHeaders();
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res)))).pipe(
       map((response: any) => {
        console.log(response)
        if (response.info) {
          response.info = response.info.replace(
            /href="(#)" data-id="([0-9]+)"/g,
            function(match, hash, id) {
              return (
                'href="/#/d/profile/' + id + '/info"' + ' data-id="' + id + '"'
              );
            }
          );
        }

        return response;
      }));
  }
  getDuetVideos(id){
    const url = this.makeUrl(`/duets/${id}/videos`);
    const options = this.getHeaders();
    return this.http2
      .get(url, options)
  }

  conversionCheck(url) {
    //let headers = this.getHeaders();
    return this.http2.get(url);
  }

  getRecord(id) {
    const url = this.makeUrl(`/recordings/${id}/record`);
    const options = this.getHeaders();
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getRecordingLayers(id) {
    const url = this.makeUrl(`/recordings/${id}/parts`);
    const options = this.getHeaders();
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }


  getSnappenins() {
    const url = this.makeUrl(`/recordings/snappenin`);
    const options = this.getHeaders();
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getSnappeninsV2(page = 1, perPage = 6) {
    const url = this.makeUrl(`/recordings/snappenin?page=${page}&per_page=${perPage}`);
    const options = this.getHeaders();
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }


  getDuetRecording(id) {
    const values$ = forkJoin(this.get(id), this.getRecord(id)).pipe(
      map(([song, record]) => {
        return { song, record };
      })
    );
    return values$;
  }

  update(id, data) {
    const url = this.makeUrl(`/user/recordings/${id}`);
    const options = this.getHeaders();
    return this.http2
      .post(url, data, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }



  updateRecordings(data) {
    const url = this.makeUrl(`/user/recordings`);
    const options = this.getHeaders();
    return this.http2
      .post(url, data, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }




  notifyReady(id) {
    const url = this.makeUrl(`/user/recordings/${id}/notify`);
    const options = this.getHeaders();
    return this.http2
      .post(url, {}, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getTopRecordings(page = 1, perPage = 6, sort = 'created_at') {
    const url = this.makeUrl(
      `/recordings?page=${page}&per_page=${perPage}&sorters={"${sort}":"DESC"}`
    );
    const options = this.getHeaders();
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  searchRecordings(page = 1, perPage = 6, term = '', sort = 'created_at') {
    const url = this.makeUrl(
      `/recordings?page=${page}&per_page=${perPage}&sorters={"${sort}":"DESC"}&q={"song.title":"${term}"}`
    );
    const options = this.getHeaders();
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }
  //{id = null, page = 1, perPage = 15, term = ''} = {}
  searchMyRecordings(
    page = 1,
    perPage = 6,
    term = '',
    sort = 'created_at',
    filters = {} as any,
    searchField = 'song.title'
  ) {
    const params: any = {};
    params.page = page + '';
    params.per_page = perPage + '';
    params.sorters = `{"${sort}"}`;

    const q: any = {};
    if (filters.public) {
      q.private = false;
      q.members_only = false;
    }
    if (filters.private) {
      q.private = true;
      q.members_only = false;
    }
    if (filters.ic) {
      q.ic = true;
    }
    if (filters.contest) {
      q.contest = true;
    }
    if (filters.members_only) {
      q.members_only = filters.members_only;
    }
    if (term) {
      q[searchField] = term;
    }

    params.q = JSON.stringify(q);

    const url = this.makeUrl(`/user/recordings`);
    const headers = this.getRawHeaders();

    return this.http2
      .get(url, { headers, params })
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }
// created V2 for MyRecordings with sort and year
  searchMyRecordingsV2(
    page = 1,
    perPage = 6,
    term = '',
    sort = {type: 'created_at', direction: 'DESC'},
    filters = {} as any,
    searchField = 'song.title',
    year
  ) {
    const params: any = {};
    params.page = page + '';
    params.per_page = perPage + '';
    if (sort.type == 'created_at') {
      params.sorters = `{"${sort.type}":"${sort.direction}"}`;
    } else if (sort.type == 'song.title'){
      params.sorters = `{"${sort.type}":"${sort.direction}"}`;
    } else if (sort.type == 'popularity'){
      params.sorters = `{"${sort.type}":"${sort.direction}"}`;
    }
   if(year != 'All'){
     params.year = year;
   }

    const q: any = {};
    if (filters.public) {
      q.private = false;
      q.members_only = false;
    }
    if (filters.private) {
      q.private = true;
      q.members_only = false;
    }
    if (filters.ic) {
      q.ic = true;
    }
    if (filters.contest) {
      q.contest = true;
    }
    if (filters.members_only) {
      q.members_only = filters.members_only;
    }
    // if (term) {
    //   q[searchField] = term;
    // }
    params.q = JSON.stringify(q);
    let url = this.makeUrl(`/user/recordings`);
    if (term) {
      url = this.makeUrl(`/user/recordings?search=${term}`);
    }
    const headers = this.getRawHeaders();
//  const url = this.makeUrl(`/user/recordings`);
    return this.http2
      .get(url, { headers, params })
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  searchMyICRecordings(
    page = 1,
    perPage = 6,
    term = '',
    sort = 'created_at',
    filters = {} as any
  ) {
    const params: any = {};
    params.page = page + '';
    params.per_page = perPage + '';

    params.sorters = `{"${sort}":"DESC"}`;

    const q: any = {};
    if (filters && typeof filters.private !== 'undefined') {
      q.private = filters.private;
    }
    if (filters && filters.members_only) {
      q.members_only = true;
    }
    if (term) {
      q['song.title'] = term;
    }

    params.q = JSON.stringify(q);

    const url = this.makeUrl(`/recordings/from/inner-circles`);
    const headers = this.getRawHeaders();

    return this.http2
      .get(url, { headers, params })
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getTrendingRecordings(page = 1, perPage = 10) {
    const url = this.makeUrl(
      `/recordings/trending?page=${page}&per_page=${perPage}`
    );
    const options = this.getHeaders();
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getFeaturedRecordings(page = 1, perPage = 10, sort = 'created_at') {
    const url = this.makeUrl(
      `/recordings/featured?page=${page}&per_page=${perPage}&sorters={"${sort}": "DESC"}`
    );
    const options = this.getHeaders();
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getUsersFeaturedRecordings(
    userId,
    page = 1,
    perPage = 10,
    sort = 'created_at'
  ) {
    const url = this.makeUrl(
      `/users/${userId}/recordings/featured?page=${page}&per_page=${perPage}&sorters={"${sort}": "DESC"}`
    );
    const options = this.getHeaders();
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getUsersFeaturedRecordingsV2(
    userId,
    page = 1,
    perPage = 10,
    sort = {sorttype: 'created_at', direction: 'DESC'}
  ) {
    const url = this.makeUrl(
      `/users/${userId}/recordings/featured?page=${page}&per_page=${perPage}&sorters={"${sort.sorttype}": "${sort.direction}"}`
    );
    const options = this.getHeaders();
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }



  getRecentlyRecorded(page = 1, perPage = 10, sort = 'created_at') {
    const url = this.makeUrl(
      `/recordings?page=${page}&per_page=${perPage}&sorters={"${sort}":"DESC"}`
    );
    const options = this.getHeaders();
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getRecentByNewUsers(page = 1, perPage = 10, sort = 'created_at') {
    const url = this.makeUrl(
      `/users/recent/recordings?page=${page}&per_page=${perPage}&sorters={"${sort}":"DESC"}`
    );
    const options = this.getHeaders();
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getRecordingsFromFavMembers(page = 1, perPage = 10, sort = 'created_at') {
    const url = this.makeUrl(
      `/recordings/from/favorite-users?page=${page}&per_page=${perPage}&sorters={"${sort}": "DESC"}`
    );
    const options = this.getHeaders();
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getRecordingsFromIC(page = 1, perPage = 10, sort = 'created_at') {
    const url = this.makeUrl(
      `/recordings/from/inner-circles?page=${page}&per_page=${perPage}&sorters={"${sort}": "DESC"}`
    );
    const options = this.getHeaders();
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getUserId() {
    return this.userId;
  }

  play(id: string | number) {
    console.log('hitting play')
    const options = this.getHeaders();
    return this.http2
      .get(this.makeUrl('/recordings/' + id + '/play'), options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  stop(id: string | number) {
    console.log('hitting stop')
    const options = this.getHeaders();
    return this.http2
      .get(this.makeUrl('/recordings/' + id + '/stop'), options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  sendPlayTime(id, time) {
    const headers = this.getHeaders();
    const payload: any = { duration: time };
    const url = this.makeUrl(`/recordings/${id}/play-pause`);
    return this.http2.post(url, payload, headers)
    .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  deleteRecording(id) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/user/recordings/${id}`);
    return this.http2
      .delete(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  deleteRecordingFromRecycleBin(id) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/user/recordings/${id}`);
    return this.http2
      .delete(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  deleteRecordings(ids) {
    let options = {
      headers: this.getRawHeaders(),
      body: { ids: ids  }
    }
    const url = this.makeUrl(`/user/recordings`);
    return this.http2
      .delete(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));

  }


  getRecordingsFromRecycleBin(page) {
    const url = this.makeUrl(`/user/recordings/recycle-bin?page=${page}`);
    const options = this.getHeaders();
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  restoreRecordingFromRecycleBin(id) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/user/recordings/recycle-bin/${id}`);
    return this.http2
      .put(url, true, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }


  fav(id: string | number) {
    const headers = this.getHttpHeaders();
    const url = this.makeUrl(`/recordings/${id}/favorite`);
    return this.http2.post<any>(url, {}, headers );
  }

  unfav(id: string | number) {
    const headers = this.getHttpHeaders();
    const url = this.makeUrl(`/recordings/${id}/favorite`);
    return this.http2.delete<any>(url, headers);
  }

  toggleFav(recording: Recording) {
    let toggleFav$;
    if (recording.favorited) {
      toggleFav$ = this.unfav(recording.id);
    } else {
      toggleFav$ = this.fav(recording.id);
    }

    return toggleFav$;
  }

  removeFromIC(icId, recordingId) {
    const headers = this.getHttpHeaders();
    const url = this.makeUrl(
      `/user/inner-circles/${icId}/recordings/${recordingId}`
    );
    return this.http2.delete<ResponseSimple>(url, headers );
  }

  private handleError(operation: string, message?: string) {
    return (err: any) => {
      console.log(`error in ${operation}()`);
      this.toastrService.error(message);
      if (err instanceof HttpErrorResponse) {
        console.log(`status: ${err.status}, ${err.statusText}`);
      }
      return observableThrowError(message);
    };
  }

  love(id: string | number) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/recordings/${id}/love`);
    return this.http2.post(url, {}, options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  unlove(id: string | number) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/recordings/${id}/love`);
    return this.http2.delete(url, options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  updateRecordState(state, recId) {
    const options = this.getHeaders();
    return this.http2
      .put(this.makeUrl('/recordings/' + recId), { private: state }, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getTags(query: string) {
    if (query === '') {
      return of([]);
    }
    const options = this.getHeaders();
    const url = this.makeUrl(`/recordings/tag?q=${query}`);
    return this.http2.get(url, options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getRecordingByTag(tag: string, page = 1, perPage = 15) {
    const options = this.getHeaders();
    return this.http2
      .get(
        this.makeUrl(`/tag/${tag}/recording?page=${page}&perPage=${perPage}`),
        options
      )
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  uploadMedia(recordingId = null, file) {
    const options = this.getHeaders();
    const fd = new FormData();
    fd.append('file', file);
    let url = this.makeUrl(`/recordings/${recordingId}/upload-media`);
    if (!recordingId) {
      url = this.makeUrl(`/recordings/upload-media`);
    }
    return this.http2
      .post(url, fd, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getUserFavRecordings(page = 1, perPage = 10) {
    const query = [];
    if (page) {
      query.push('page=' + page);
    }
    if (perPage) {
      query.push('per_page=' + perPage);
    }
    const options = this.getHeaders();
    const url = this.makeUrl('/user/favorites/recording', {
      query: query.join('&'),
    });
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getUserFavRecordingsV2(page = 1, perPage = 10, sort = 'created_at') {
    const options = this.getHeaders();
    const url = this.makeUrl(
      `/user/favorites/recording?page=${page}&per_page=${perPage}&sorters={"${sort}": "DESC"}`
    );
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

/*  getUserRecordings(page = 1, perPage = 10, sorting?) {
    const query = [];
    if (page) {
      query.push('page=' + page);
    }
    if (perPage) {
      query.push('per_page=' + perPage);
    }
    if (sorting) {
      query.push(sorting);
    }

    const options = this.getHeaders();
    const url = this.makeUrl('/user/recordings', { query: query.join('&') });
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }
*/
  //Changed to support URL with correct sorting
  getUserRecordings(page = 1, perPage = 10, sort = 'created_at') {
    const url = this.makeUrl(
      `/users/recordings?page=${page}&per_page=${perPage}&sorters={"${sort}": "DESC"}`
    );
    const options = this.getHeaders();
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

//added sort direction
  getUserRecordingsV2(page = 1, perPage = 10, sort = {sorttype: 'created_at', direction: 'DESC'}) {
    const url = this.makeUrl(
      `/users/recordings?page=${page}&per_page=${perPage}&sorters={"${sort.sorttype}": "${sort.direction}}`
    );
    const options = this.getHeaders();
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getUserDeletedRecordings(id, page = 1, perPage = 10, sort = 'created_at') {
    const url = this.makeUrl(
      `/users/${id}/recordings?page=${page}&per_page=${perPage}&sorters={"${sort}": "DESC"}`
    );
    const options = this.getHeaders();
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }


  getUserRecordingByid(id) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/user/recordings/${id}`);
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }
/*  getUsersRecordings(id, page, perPage, sorting?) {
    const query = [];
    if (page) {
      query.push('page=' + page);
    }
    if (perPage) {
      query.push('per_page=' + perPage);
    }
    if (sorting) {
      query.push(sorting);
    }
    const options = this.getHeaders();
    const url = this.makeUrl('/users/' + id + '/recordings', {
      query: query.join('&'),
    });
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }
*/
  //Changed to support URL with correct sorting
    getUsersRecordings(id, page = 1, perPage = 10, sort = 'created_at') {
    const url = this.makeUrl(
      `/users/${id}/recordings?page=${page}&per_page=${perPage}&sorters={"${sort}": "DESC"}`
    );
    const options = this.getHeaders();
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

 //Added sort direction
  getUsersRecordingsV2(id, page = 1, perPage = 10, sort = {sorttype: 'created_at', direction: 'DESC'}) {
    const url = this.makeUrl(
      `/users/${id}/recordings?page=${page}&per_page=${perPage}&sorters={"${sort.sorttype}": "${sort.direction}"}`
    );
    const options = this.getHeaders();
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  sendComment(comment: string, recordingId, replyToId?) {
    const options = this.getHeaders();
    let url = `/recordings/${recordingId}/comments`;
    url += '?per_page=10';
    const payload: any = { body: comment };
    console.log(comment);
    if (replyToId) {
      payload.reply_to = replyToId;
    }
    return this.http2
      .post(this.makeUrl(url), payload, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res)))).pipe(
      map((response: any) => {
        const data = response.model;

        for (let i = 0; i < data.length; i++) {
          data[i].body = data[i].body.replace(
            /href="(#)" data-id="([0-9]+)"/g,
            function(match, hash, id) {
              return (
                'href="/#/d/profile/' + id + '/info"' + ' data-id="' + id + '"'
              );
            }
          );
        }

        response.model = data;
        return response;
      }));
  }

  updateComment(commentBody: string, commentId) {
    const options = this.getHeaders();
    const payload: any = { body: commentBody };
    return this.http2
      .put(this.makeUrl(`/comments/${commentId}`), payload, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res)))).pipe(
      map((response: any) => {
        const data = response.model;

        data.body = data.body.replace(
          /href="(#)" data-id="([0-9]+)"/g,
          function(match, hash, id) {
            return (
              'href="/#/d/profile/' + id + '/info"' + ' data-id="' + id + '"'
            );
          }
        );

        response.model = data;
        return response;
      }));
  }

  deleteComment(commentId) {
    const options = this.getHeaders();
    return this.http2
      .delete(this.makeUrl(`/user/comments/${commentId}`), options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  reportComment(commentId, report) {
    const options = this.getHeaders();
    return this.http2
      .post(this.makeUrl(`/comments/${commentId}/report`), report, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getComment(recordingId, commentId) {
    const options = this.getHeaders();
    return this.http2
      .get(
        this.makeUrl(`/recordings/${recordingId}/comments/${commentId}`),
        options
      )
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getComments(recordingId, page = 1, perPage = 25, sort = 'ASC') {
    const options = this.getHeaders();
    return this.http2
      .get(
        this.makeUrl(
          `/recordings/${recordingId}/comments?page=${page}&per_page=${perPage}&sorters={"created_at": "${sort}"}`
        ),
        options
      )
      .pipe(map((res) => JSON.parse(JSON.stringify(res)))).pipe(
      map((response: any) => {
        const data = response.data;

        for (let i = 0; i < data.length; i++) {
          data[i].body = data[i].body.replace(
            /href="(#)" data-id="([0-9]+)"/g,
            function(match, hash, id) {
              return (
                'href="/#/d/profile/' + id + '/info"' + ' data-id="' + id + '"'
              );
            }
          );
        }

        response.data = data;
        return response;
      }));
  }

  getReceivedComments(page = 1, perPage = 15) {
    const options = this.getHeaders();
    return this.http2
      .get(
        this.makeUrl(
          `/user/comments/received?page=${page}&per_page=${perPage}&sorters={"created_at": "DESC"}`
        ),
        options
      )
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getPostedComments(page = 1, perPage = 15) {
    const options = this.getHeaders();
    return this.http2
      .get(
        this.makeUrl(
          `/user/comments/sent?page=${page}&per_page=${perPage}&sorters={"created_at": "DESC"}`
        ),
        options
      )
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getReplies(commentId, page = 1, perPage = 10) {
    const options = this.getHeaders();
    return this.http2
      .get(
        this.makeUrl(
          `/comments/${commentId}/replies?page=${page}&per_page=${perPage}`
        ),
        options
      )
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getOpenJams(page = 1, perPage = 10, sort = 'created_at') {
    const options = this.getHeaders();
    return this.http2
      .get(
        this.makeUrl(
          `/jams?page=${page}&per_page=${perPage}&sorters={"${sort}": "DESC"}`
        ),
        options
      )
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getDuets(page = 1, perPage = 10, sort = 'created_at') {
    const options = this.getHeaders();
    return this.http2
      .get(
        this.makeUrl(
          `/duets/completed?page=${page}&per_page=${perPage}&sorters={"${sort}": "DESC"}`
        ),
        options
      )
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getOpenDuets(page = 1, perPage = 10, sort = 'created_at') {
    const options = this.getHeaders();
    return this.http2
      .get(
        this.makeUrl(
          `/duets?page=${page}&per_page=${perPage}&sorters={"${sort}": "DESC"}`
        ),
        options
      )
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getOpenDuetsSeeMore(page = 1, perPage = 10, sort = 'created_at', asc = false, query,  decade = '', video = 0
    ) {
      let url;
      if(decade != '') {
        url = this.makeUrl('/duets?decade=' + decade);
      } else {
        url = this.makeUrl('/duets');
  
      }
      const options = this.getHeaders();
      const params = this.utilService.buildParams(
        page,
        perPage,
        sort,
        asc,
      );

     


      if(typeof query == 'object') {
        if(Object.keys(query).length != 0) {
          params.q = '{'
          Object.keys(query).forEach((key) => {
            let value = query[key]
            params.q += `"${key}":"${value}",`
          })
         if(video == 1) {
            params.q += `"webcam":true,`;
          } else if (video == 2){
            params.q += `"webcam":false,`;
            params.q = params.q.substring(0, params.q.length - 1) + '}'
          } 
        }  else if (video == 1) {
          params.q = `{"webcam": true}`;
       } else if (video == 2) { 
         params.q =`{"webcam": false}`;
       }    


       
      } else if (video == 1) {
        params.q = `{"webcam": true}`;
        } else if (video == 2) { 
        params.q = `{"webcam": false}`;
        }  


      options['params'] = params;
      return this.http2
        .get(url, options)
        .pipe(map((res) => JSON.parse(JSON.stringify(res))));
    }


/*
  getFavoritesSeeMore(
    page = 1,
    perPage = 10,
    sort = 'created_at',
    asc = true,
    query,
    decade = ''
  ) {
    let url;
    if(decade != '') {
      url = this.makeUrl('/user/favorites/song?decade=' + decade);
    } else {
      url = this.makeUrl('/user/favorites/song');

    }
    const options = this.getHeaders();
    const params = this.utilService.buildParams(
      page,
      perPage,
      sort,
      asc,
    );
    if(typeof query == 'object') {
      if(Object.keys(query).length != 0) {
        params.q = '{'
        Object.keys(query).forEach((key) => {
          let value = query[key]
          params.q += `"${key}":"${value}",`
        })
        params.q = params.q.substring(0, params.q.length - 1) + '}'
        console.log(params.q)
      }
    }

    options['params'] = params;
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }


*/






  getOpenRecordings(userId, page = 1, perPage = 10, sort = 'created_at') {
    const options = this.getHeaders();
    return this.http2
      .get(
        this.makeUrl(
          `/users/${userId}/recordings/open?page=${page}&per_page=${perPage}&sorters={"${sort}": "DESC"}`
        ),
        options
      )
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getOpenRecordingsV2(userId, page = 1, perPage = 10, sort = {sorttype: 'created_at', direction: 'DESC'}) {
    const options = this.getHeaders();
    return this.http2
      .get(
        this.makeUrl(
          `/users/${userId}/recordings/open?page=${page}&per_page=${perPage}&sorters={"${sort.sorttype}": "${sort.direction}"}`
        ),
        options
      )
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  searchOpenDuets(page = 1, perPage = 10, term = '', sort = 'created_at') {
    const options = this.getHeaders();
    return this.http2
      .get(
        this.makeUrl(
          `/duets?page=${page}&per_page=${perPage}&sorters={"${sort}": "DESC"}&q={"song.title": "${term}"}`
        ),
        options
      )
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  reaction(recordingId, reactionId) {
    const options = this.getHeaders();
    const payload: any = { reaction_id: reactionId };
    return this.http2
      .post(this.makeUrl(`/recordings/${recordingId}/react`), payload, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  reactionComment(commentId, reactionId) {
    const options = this.getHeaders();
    const payload: any = { reaction_id: reactionId };
    return this.http2
      .post(this.makeUrl(`/comments/${commentId}/react`), payload, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  favoriteComment(commentId) {
    const options = this.getHeaders();
    return this.http2
      .post(this.makeUrl(`/comments/${commentId}/love`), {}, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  unFavoriteComment(commentId) {
    const options = this.getHeaders();
    return this.http2
      .delete(this.makeUrl(`/comments/${commentId}/love`), options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  reportRecording(id, reason) {
    const options = this.getHeaders();
    return this.http2
      .post(this.makeUrl(`/recordings/${id}/report`), reason, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  appearOnLegacy(id) {
    const options = this.getHeaders();
    return this.http2
      .post(this.makeUrl(`/recordings/${id}/legacy`), {}, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getRecordingsBySong(songId, page = 1, perPage = 6, sort = 'created_at') {
    const url = this.makeUrl(
      `/songs/${songId}/recordings?page=${page}&per_page=${perPage}&sorters={"${sort}":"DESC"}`
    );
    const options = this.getHeaders();
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getAvailableRecordingsForContest({
    contestId = null,
    page = 1,
    perPage = 6,
    sort = 'created_at',
    term = '',
  } = {}) {
    const url = this.makeUrl(
      `/user/contests/${contestId}/recordings?page=${page}&per_page=${perPage}&sorters={"${sort}":"DESC"}&q={"song.title":"${term}"}`
    );
    const options = this.getHeaders();
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getRemixData(id) {
    let headers = this.getNoCacheHeaders();
    const url = this.makeUrl(`/user/recordings/${id}/remix`);
    return this.http2.get(url, headers);
  }

  saveRemix(id, data) {
    let headers = this.getHeaders();
    const url = this.makeUrl(`/user/recordings/${id}/remix`);
    return this.http2.post(url, data, headers);
  }
}
