import { ChangeDetectorRef, NgZone, Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import {
  SongService,
  MessageBoardService,
  ProfileService,
  RecordingService,
  UtilService,
  HelperService,
  EventService,
} from '@app/core';
import { FullscreenModalComponent } from '@app/shared/fullscreen-modal/fullscreen-modal.component';

@Component({
  selector: 'app-home-re',
  templateUrl: './home-re.component.html',
  styleUrls: ['./home-re.component.scss'],
})
export class HomeReComponent implements OnInit {
  @ViewChild('playlist', { static: true }) private playlistElem;
  @ViewChild('fsModal', { static: true }) fsModal: FullscreenModalComponent;
  items = [];
  trendingSongs = [];
  snappenins: any = [];
  recommendedSongs$: any;
  snappinLoading: boolean = false;
  loggedInIsGold = true;

  announcementData = {
    ...this.util.generateGridData(),
    sort: {},
    fetch: this.messageBoardService.getAnnouncements.bind(
      this.messageBoardService
    ),
  };

  topRecordingData: any = {
    ...this.util.generateGridData(),
    fetch: this.recordingService.getTopRecordings.bind(this.recordingService),
  };

  releasedData: any = {
    ...this.util.generateGridData(),
    fetch: this.songService.getNewReleases.bind(this.songService),
  };
  songSortOptions = [];
  recordingSortOptions = [];

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private toastService: ToastrService,
    private recordingService: RecordingService,
    private songService: SongService,
    private helperService: HelperService,
    private messageBoardService: MessageBoardService,
    public profileService: ProfileService,
    private eventService: EventService,
    public util: UtilService,
    private cdr: ChangeDetectorRef
  ) {
    this.songSortOptions = this.util.songSortOptions;
    this.recordingSortOptions = this.util.recordingSortOptions;
    this.releasedData.sort = this.util.songSortOptions[0];
    this.topRecordingData.sort = this.util.recordingSortOptions[0];
  }

  // async ngOnInit() {
  //    this.songService.getRecommended().subscribe(response => {
  //     this.trendingSongs = response['data'];
  //   });
  //   try {
  //     const result = await this.recordingService.getSnappenins().toPromise();
  //     this.snappenins = result.data;
  //    } catch (err) {
  //     this.toastService.error('Failed to load snappenins.');
  //   }

  //   this.onScrollEndHandle(this.topRecordingData, null);
  //   this.onScrollEndHandle(this.releasedData, null);
  //   this.onScrollEndHandle(this.announcementData, null);
  // }

  ngOnInit() {
    // this.songService.getTrendingSongs().subscribe(response => {
    // this.trendingSongs = response['data']; });
  this.snappinLoading = true;
  this.recordingService.getSnappenins().subscribe(response => {
  this.snappenins = response['data']; this.snappinLoading = false; this.detectChanges();});
  this.onScrollEndHandle(this.topRecordingData, null);
  this.onScrollEndHandle(this.releasedData, null);
  this.onScrollEndHandle(this.announcementData, null);
  let loggedIn = this.util.getloggedUserData();
  this.loggedInIsGold = loggedIn.gold;

}
  public navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }

  checkMine(item){
    return item.favorited;
  }

  favSong(song) {
    this.songService.fav(song.id).subscribe(response => {
      if (response.success) {
        song.favorited = true;
        this.detectChanges();
        this.toastService.success('Song was added to favorites.');
      } else {
        this.toastService.error('There was an error, try again later.');
      }
    });
  }
  navigateSong(id){
    this.navigate([`/recorder/${id}`])
  }

  navListen(id){
    this.navigate([`/d/listen/${id}`])
  }

  navArtistProfile(id){
    this.navigate([`/d/artists/${id}`])
  }

  navProfile(id){
    this.navigate([`/d/profile/${id}`])
  }

  unFavSong(song) {
    this.songService.unfav(song.id).subscribe(response => {
      if (response.success) {
        song.favorited = false;
        this.detectChanges();
        this.toastService.success('Song was removed from favorites.');
      } else {
        this.toastService.error('There was an error, try again later.');
      }
    });
  }

  favRecording(recording) {
    console.log(recording);
    this.recordingService.fav(recording.id).subscribe(response => {
      recording.favorited = true;
      this.detectChanges();
      if (response.success) {
        this.toastService.success('Recording was added to favorites.');
      } else {
        this.toastService.error('There was an error, try again later.');
      }
    });
  }

  unFavRecording(recording) {
    this.recordingService.unfav(recording.id).subscribe(response => {
      recording.favorited = false;
      this.detectChanges();
      if (response.success) {
        this.toastService.success('Recording was removed from favorites.');
      } else {
        this.toastService.error('There was an error, try again later.');
      }
    });
  }

  detectChanges() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  onScrollEndHandle(data, sort) {
    if (sort) {
      data.sort = sort;
    }

    if (!data.loading && data.lastPage) {
      data.loading = true;
      data.currentPage++;
     // this.detectChanges();
      data
        .fetch(data.currentPage, data.perPage, data.sort.field, data.sort.asc)
        .subscribe(
          response => {
            data.items = data.items.concat(response.data);
            data.lastPage = response.next_page_url;
            data.loading = false;
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
            data.loading = false;
          }
        );
    }
  }

  onScrollEndFreeSongsHandle(data, sort, reload = false) {
    if (reload || (!data.loading && data.lastPage)) {
      data.loading = true;
      data.currentPage++;
     // this.detectChanges();
      data
        .fetch(data.currentPage, data.perPage, 'gold', data.freeSongsFirst)
        .subscribe(
          response => {
            data.items = data.items.concat(response.data);
            data.lastPage = response.next_page_url;
            data.loading = false;
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
            data.loading = false;
          }
        );
    }
  }

  resetDataObj(data) {
    data.currentPage = 0;
    data.loading = false;
    data.items = [];
    data.lastPage = 1;
    return data;
  }

  shareRecording(recording) {
    this.eventService.onSocialShare({ recording });
  }

  recordingToLegacy(recording) {
    this.recordingService.appearOnLegacy(recording.id).subscribe(
      response => {
        this.toastService.success('Recording will appear on legacy');
      },
      () => {
        this.toastService.success("There's an error, please try again later.");
      }
    );
  }

  onAddtoPlaylist(id) {
    this.playlistElem.open(id);
  }

  async subscribeUser(recording) {
    this.helperService.subscribeUser(recording, [this.topRecordingData.items]);
  }

  async unsubscribeUser(recording) {
    this.helperService.unsubscribeUser(recording, [
      this.topRecordingData.items,
    ]);
  }

  handleFreeSongsChange(data, gold) {
    console.log(data, gold);
    data.freeSongsFirst = gold;
    this.resetDataObj(data);
    this.onScrollEndFreeSongsHandle(data, '', true);
  }

  howOthersSingThis(id){
    this.navigate([`/d/ListenOthersSing/${id}`]);
  }
}
