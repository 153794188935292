import { WebsocketService } from './../../core/services/websocket.service';
import {environment} from './../../../environments/environment';
import {takeUntil} from 'rxjs/operators';
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  NgZone,
  HostListener
} from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { concat, differenceBy, uniqBy } from 'lodash';
import { Location } from '@angular/common';
import Bugsnag from '@bugsnag/js';
import { RosesService } from '@app/core/services/roses.service';
import { EmailNotificationsService } from '@app/core/services/email-notifications.service';

import {
  EventService,
  RecordingService,
  UsersService,
  UtilService,
  ProfileService,
  HelperService,
  ContestService,
  SongService,
  UserService,
  Data,
} from '@app/core';
import { ReactionComponent } from '@app/shared/reaction/reaction.component';
//import { PusherService } from '@app/core/services/pusher.service';
import { ToastrService } from 'ngx-toastr';
declare var gtag;

@Component({
  selector: 'app-listen-re',
  templateUrl: './listen-single-re.component.html',
  styleUrls: ['./listen-single-re.component.scss'],
})
export class ListenSingleReComponent implements OnInit, OnDestroy {
  @HostListener('window:keydown', ['$event'])
  keyEvent(e: KeyboardEvent) {
   if ( e.code == 'ControlLeft' || e.code == 'ControlRight') {
      e.stopImmediatePropagation();
      this.isControlPressed = true;
     e.preventDefault();
    } else {
      this.isControlPressed = false;
    }
  }
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @ViewChild('playlist', { static: true }) private playlistElem;
  @ViewChild('report', { static: true }) private reportElem;
  @ViewChild('goldcontent', { static: true }) private goldModal;
  @ViewChild('blockedModal', { static: true }) private blockedModal;
  @ViewChild('snap', { static: true }) private snapModal;

  isControlPressed = false;
  me;
  recording;
  comments = [];
  commentsTotal = 0;
  viewsTotal = 0;
  recordingId: number;
  reactionList = [];
  reactions = [];
  converting = false;
  favoriting = null;
  currentCommentPage = 1;
  loadingComments = true;
  volume = 0.5;
  throttle = 300;
  allowDuets = false;
  openAdmin = false;

  scriptTag;
  scrollDistance = 1;
  scrollUpDistance = 2;
  lastPage = 1;
  nextPageUrl;
  channel = null;
  reporting = false;
  recordings = {
    items: [],
    current: 1,
    loadedAll: false,
    loading: false,
  };
  recordingsLayers = {
    items: [],
    current: 1,
    loadedAll: false,
    loading: false,
  };
  recordingLayersAll: any;
  candidate: boolean;
  candidacyId: string;
  creationYear;
  recordingItem;
  rosesCount = 0
  loadingRecording = false;

  @ViewChild('listenReaction', { static: true }) listenReaction: ReactionComponent;

  private favoriteSource = new Subject<any>();
  favorite$ = this.favoriteSource.asObservable();

  messageFromSocket: string;
  socketValues: any;
  loggedInIsGold = true;
  constructor(
    private recordingService: RecordingService,
    //private pusherService: PusherService,
    private router: Router,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private toastService: ToastrService,
    public profileService: ProfileService,
    private usersService: UsersService,
    private cdr: ChangeDetectorRef,
    private contests: ContestService,
    private rosesService: RosesService,
    private location: Location,
    private events: EventService,
    private helpers: HelperService,
    private songService: SongService,
    private wsService: WebsocketService,
    private ngZone: NgZone,
    private userService: UserService,
    private emailNotificationsService: EmailNotificationsService,
    private data: Data,

  ) {
    this.activatedRoute.params.subscribe(params => {
      const isNew = !this.recordingId;
      if (!isNew) {
        //this.blockedModal.close()
        this.converting = false;
        this.allowDuets = false;
        this.me = null;
        this.recording = null;
        this.comments = [];
        this.commentsTotal = 0;
        this.reactionList = [];
        this.reactions = [];
        this.favoriting = null;
        this.currentCommentPage = 1;
        this.loadingComments = true;
        this.lastPage = 1;
        this.nextPageUrl;
        this.channel = null;
        this.reporting = false;
        this.rosesCount = 0
        this.recordings = {
          items: [],
          current: 1,
          loadedAll: false,
          loading: false,
        };
        this.recordingsLayers = {
          items: [],
          current: 1,
          loadedAll: false,
          loading: false,
        };
        this.recordingLayersAll = [];
      }
      if(localStorage.getItem('listenVolume')) {
        console.log(localStorage.getItem('listenVolume'))
        this.volume = parseFloat(localStorage.getItem('listenVolume'))
        console.log(this.volume)
    }
      this.recordingId = null;


      setTimeout(() => {
        this.loadScripts();
        this.recordingId = params.id;
        this.initLoading(isNew);
      });

      this.candidacyId = params.candidacyId;
    });
    this.profileService.getUserProfile().subscribe(user => {
      this.me = user;
    });

    this.route.data.subscribe(data => {
      this.candidate = data.candidate;
    });
  }
  loadScripts() {
    this.scriptTag = document.createElement('script');
    this.scriptTag.src = '../../../assets/scripts/start-lyrics.js';
    this.scriptTag.id = 'startLyrics';
    this.scriptTag.type = 'text/javascript';
    this.scriptTag.async = false;
    this.scriptTag.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(this.scriptTag);
  }
  public navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }
  ngOnInit() {
    // this.initLoading();)
    let loggedIn = this.utilService.getloggedUserData();
    this.loggedInIsGold = loggedIn.gold;
  }

  start() {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  initLoading(isNew) {
    // this.channel = this.pusherService.pusher.subscribe(
    //   'private-recording-' + this.recordingId
    // );
    //this.channel.bind('pusher:subscription_succeeded', function() {});
    //this.channel.bind('new-message', function(data) {});
   // if(isNew) {
      this.loadRecordings();
    //}
    this.getRecording();

    this.listenReaction.emoji$.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(emojiReaction => {
        this.recordingService
          .reaction(this.recordingId, emojiReaction.id).pipe(
          takeUntil(this.ngUnsubscribe))
          .subscribe(({ success, reactions }) => {
            if (success) {
              this.reactionList = reactions.concat([]);

                this.data.reactionSentEmoji = emojiReaction.code;
                this.data.reactionSent = true;
                // // send email
                // if(Object.keys(this.data.recLayers).length > 1){
                //   let recLayerList = '';
                //   for(var i=0;i<Object.keys(this.data.recLayers).length;i++) {
                //     if( i!= Object.keys(this.data.recLayers).length){
                //       recLayerList += this.data.recLayers[i].name + ', ';
                //     }
                //   }
                //   let newRecordingName = this.data.sendCommentData.recording_song_name + " featuring " + recLayerList.slice(0, -2);
                //   newRecordingName = newRecordingName.substring(0, newRecordingName.lastIndexOf(', ')) + ' &' + newRecordingName.substring(newRecordingName.lastIndexOf(', ') + 1);
                //   for(var i=0;i<Object.keys(this.data.recLayers).length;i++) {
                //     if(!this.data.recLayers[i].isme && this.data.recLayers[i].newsletter){
                //       this.emailNotificationsService.emailReactionFeaturedOnNotification (this.data.recLayers[i].email, this.data.recLayers[i].name, this.data.sendCommentData.sender_name, newRecordingName, this.data.sendCommentData.share_url,emojiReaction.code);
                //     }
                //   }
                // } else {
                //     if(this.data.sendCommentData.recipient_wants_newsletter){
                //       this.emailNotificationsService.emailReactionNotification (this.data.sendCommentData.email, this.data.sendCommentData.recipient_name, this.data.sendCommentData.sender_name, this.data.sendCommentData.recording_song_name, this.data.sendCommentData.share_url,emojiReaction.code);
                //     }
                // }

              this.reactions = reactions.filter(reaction => {
                return reaction.count > 0;
              });
              if (this.reactions.length > 1) {
                this.reactions.sort((a, b) => {
                  return b.count - a.count;
                });
              }
            }
          });
      });

    this.favorite$.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(({ success, model, message }) => {
        this.favoriting = null;
        if (success == true) {
          this.recording.favorited = model.favorited;
          this.recording.stats.love = model.stats.love;
        }
      });
  }

  onReport() {
    this.reportElem.popup('Recording');
  }

  reportRecording(reason) {
    console.log('reporting', reason);
    this.reporting = true;
    this.recordingService
      .reportRecording(this.recordingId, reason)
      .subscribe(result => {
        this.reporting = false;
        this.reportElem.onReportSuccess();
      });
  }

  onKeydown(e){
    // if (e.code == 'ControlLeft' || e.code == 'ControlRight') {
    //   this.userService.getIsAdmin().subscribe(response => {
    //     if (response.success) {
    //        this.openAdmin = true;
    //     }    
    //   }, error => {
    //   });
    //   this.openAdmin = false;
    // }

    if (e.code == 'ControlLeft' || e.code == 'ControlRight') {
      this.openAdmin = true;      
    }



  }
  onKeyup(e){
    this.openAdmin = false;
  }

  loadRecordings() {
    if (this.recordings.loading || this.recordings.loadedAll) {
      return;
    }
    this.recordings.loading = true;
    this.recordingService
      .getTopRecordings(this.recordings.current).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(result => {
        this.recordings.items.push(...result.data);
        this.recordings.current++;
        this.recordings.loading = false;
        this.recordings.loadedAll = !result.next_page_url;
      });
    }


    getRecordingLayers(id){
      this.recordingService.getRecordingLayers(id).subscribe(result => {
         this.recordingsLayers.items.push(...result.data);
        });
     }



  onPlayRecording(recording) {
    gtag("event", "listen", {
      action: "Load Recording",
      recId: recording.id.toString(),
      recSongId: recording.song.id.toString(),
      songId: recording.song.id.toString()
     });
    this.router.navigate(['/d/listen/', recording.id]);
  }

  onProfile(user) {
    this.openLink('/d/profile/' + user.id + '/info');
  }

  openLink(url) {
    window.open(window.location.origin + '/#' + url);
  }

  handleSing(song) {
    if (song.gold && !this.profileService.user.gold) {
      this.goldModal.open();
      return;
    }
    this.router.navigate(['/recorder', song.id]);
  }

  handleDuet(duet) {
    if (duet.song.gold && !this.profileService.user.gold) {
      this.goldModal.open();
      return;
    }
    this.router.navigate(['/duet-recorder', duet.id]);
  }

  getRecording() {
 
  //  this.wsService.createObservableSocketRecordingViewCount(`recordings:${this.recordingId}`)
   //   .subscribe(data => {
   //     this.messageFromSocket = data;
   //     this.socketValues = JSON.parse(this.messageFromSocket);
       //  this.viewsTotal = this.socketValues.views;
       // this.commentsTotal = this.socketValues.comments;
   //   });

    this.loadingRecording=true;
    this.getRecordingLayers( this.recordingId );
    this.rosesService.getRoses(this.recordingId).subscribe(roses =>{
      this.rosesCount = roses['count']
    })
    this.recordingService
      .get(this.recordingId).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(
        recording => {
          if(recording.info == null) {
            recording.info = '';
          }
          console.log(recording);
          if(recording.allow_duets) {
            this.allowDuets = true;
          }
          this.creationYear = parseInt(recording.created_at.substr(0,4));
          console.log(this.creationYear)
          this.commentsTotal = recording.stats.comments;
          this.viewsTotal = recording.stats.views;
          this.reactionList = recording.reactions.concat([]);
          this.reactions = recording.reactions.filter(reaction => {
            return reaction.count > 0;
          });
          if (this.reactions.length > 1) {
            this.reactions.sort((a, b) => {
              return b.count - a.count;
            });
          }
          this.recording = recording;
          this.recording.icons = this.utilService.getRecordingPrivacyIcons(
            recording
          );
          if (this.recording.users) {
            this.recording.users = uniqBy(this.recording.users, 'id');
          }else {
            this.recording['users'][0] = this.recording.user;
          }
          this.loadingRecording = false;
        },
        error => {
          if (typeof error.error.id == 'undefined') { // if recording isn't found
            if(error.error.reason == 'BLOCKED'){
              this.blockedModal.open(error.error.message);
              Bugsnag.notify('Player error: ' + error.error.message);
            } else {
              let msg = "Recording not found!"
              this.blockedModal.open(msg);
              Bugsnag.notify('Player error: ' + msg);
            }
          } else {
            if(error.status == '403') { // if first play from v2
                      this.converting = true;
                      this.recording = false;
                      console.log(error)
                      let conversionCheck = setInterval(()=>{
                          //checks every 5 seconds if the recording is done being converted
                          this.recordingService.conversionCheck(error.error.uri).pipe(
                              takeUntil(this.ngUnsubscribe))
                              .subscribe((data) => {
                                console.log('getting data')
                                  if(data['status'] == '200') {
                                      //reloads once the recording is converted and ready to play
                                      clearInterval(conversionCheck)
                                      location.reload();
                                  }
                              }, (error) =>
                              {
                                console.log('getting an error')
                                let msg = this.recordingId.toString();
                                Bugsnag.notify('Player, error loading first play of recID: ' + msg);
                                if(error['status'] == '200') {
                                  //reloads once the recording is converted and ready to play
                                  clearInterval(conversionCheck)
                                  location.reload();
                                  Bugsnag.notify('Player, error loading first play of recID is now successful: ' + msg);  
                              }
                              })

                      }, 5000)
                  }
              }

        }
      );
 
  }

 


  handleBlockedClosed() {
      this.router.navigate(['/home']);
  }

  toggleFavorite() {
    this.favoriting = '';
    (this.recording.favorited
      ? this.unFavoriteRecording()
      : this.favoriteRecording()
    ).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.favoriteSource.next(data);
      });
  }

  favoriteRecording() {
    return this.recordingService.fav(this.recordingId);
  }

  unFavoriteRecording() {
    return this.recordingService.unfav(this.recordingId);
  }


  toggleLove() {
    this.favoriting = true;
    (this.recording.loved ? this.unLoveRecording() : this.loveRecording()).pipe(
        takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            this.recording.loved = data.model.loved;
            this.recording.stats.love = data.model.stats.love;
            this.favoriting = false;
            if (data.success) {
            }
            // this.onLove.emit({
            //     success: true,
            //     data: data
            // });
        });
}

loveRecording() {
    return this.recordingService.love(this.recording.id);
}

unLoveRecording() {
    return this.recordingService.unlove(this.recording.id);
}




  getComments() {
    console.log('getting comments');
    console.log(this.lastPage)
    if (this.lastPage >= this.currentCommentPage) {
    //if(this.nextPageUrl){
      this.loadingComments = true;
      this.recordingService
        .getComments(this.recordingId, this.currentCommentPage, 10).pipe(
        takeUntil(this.ngUnsubscribe))
        .subscribe(this.processData.bind(this));
    }
  }

  processData(data) {
    console.log(data)
    this.currentCommentPage++;
    this.lastPage = data.last_page;
    this.nextPageUrl = data.next_page_url;
    // this.commentsTotal = data.stats.comments;

    let newComments = data.data;

    if (this.comments.length > 0) {
      newComments = differenceBy(data.data, this.comments, 'comment_id');
    }
    this.comments = concat(this.comments, newComments);
    this.loadingComments = false;
  }

  getFavoriteTooltip(recording) {
    return recording && recording.favorited
      ? 'Unlove this recording.'
      : 'Love this recording.';
  }

  onLoveHandle($event) {
    console.log($event);
    //this.toggleFavorite();
  }

  onAddtoPlaylist() {
    this.playlistElem.open(this.recordingId);
  }

  favRecording(recording) {
    this.helpers.favoriteRecording(recording);
  }

  unFavRecording(recording) {
    this.helpers.unfavoriteRecording(recording);
  }

  acceptCandidate(id) {
    this.helpers.acceptContestCandidate(id);
    this.candidate = false;
  }

  rejectCandidate(id) {
    this.helpers.rejectContestCandidate(id);
    this.candidate = false;
  }

  detectChanges() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  async subscribeUser(recording) {
    const recordingList = [this.recordings.items];
    this.helpers.subscribeUser(recording, recordingList);
  }

  async unsubscribeUser(recording) {
    const recordingList = [this.recordings.items];
    this.helpers.unsubscribeUser(recording, recordingList);
  }

  openSnapModal() {
    this.snapModal.open(this.recording.user);
  }

  sendGift() {
    this.events.onSendGift(this.recording.user);
  }

  sendSnap() {
    this.events.onSendSnapCredit(this.recording.user);
  }

  shareRecording(recording) {
    if(recording.allow_share == false || recording.private == true)
    {
      return;
    }
    this.events.onSocialShare({ recording });
  }

  setRecording(item){
    this.recordingItem = item;

 }
 getRecordingTag(recordingItem, currentTruncateTextLength) {

     if( !recordingItem.badges.name){return ;}
     this.setRecording(recordingItem);

     let titleLength = recordingItem.song.title.length;
     let tagTypeName = recordingItem.badges.name;
     let tagTypeAmount : number ;
     let tagNameLong = "";
     let tagName = "";
     let truncateLength: number;

     tagTypeAmount = recordingItem.badges.qty;
     tagNameLong = tagTypeName + (tagTypeAmount>2?  ":" + tagTypeAmount : '');

     if ( (titleLength + tagNameLong.length)  <= currentTruncateTextLength  ){
         tagName = tagNameLong;
     } else{
         tagName = tagTypeName[0] + (tagTypeAmount>2?  ":" + tagTypeAmount : '');
     }
     return tagName ;
   }

   setColor(firstLetter){
     let color: any;
     switch(firstLetter){
       case 'h':
       case 'd':
         color='#58C7C9';
         break;
       case 'j':
         color='#cd00cd';
         break;
     }
     return color;
   }

   getStyle(recording){
     let outColor: string;
     if(recording.badges.name){
       outColor = this.setColor(recording.badges.name[0]);
       return {'color': outColor, 'border-color': outColor  };
     }
     return ;
   }

   getTruncateValue(recording, currentTruncateTextLength){
     if( !recording.badges.name){return currentTruncateTextLength;}
     let titleLength = recording.song.title.length;
     let tagTypeName = recording.badges.name;
     let tagTypeAmount : number ;
     let tagNameLong = "";
     let tagName = "";
     let truncateLength: number;

     tagTypeAmount = recording.badges.qty;
     tagNameLong = tagTypeName + (tagTypeAmount>2?  ":" + tagTypeAmount : '');

     if ( (titleLength + tagNameLong.length)  <= currentTruncateTextLength  ){
         tagName = tagNameLong;
     } else{
         tagName = tagTypeName[0] + (tagTypeAmount>2?  ":" + tagTypeAmount : '');
     }
     return truncateLength = (currentTruncateTextLength - tagName.length);
   };


   favUser(user) {
    this.helpers.favoriteMemberWithFrom(user, this.me);
  }

  unfavUser(user) {
    this.helpers.unfavoriteMember(user);
  }




  favSong(song) {
    this.songService.fav(song.id).subscribe(response => {
      if (response.success) {
        song.favorited = true;
        this.detectChanges();
        this.toastService.success('Song was added to favorites.');
      } else {
        this.toastService.error('There was an error, try again later.');
      }
    });
  }
  
  unFavSong(song) {
    this.songService.unfav(song.id).subscribe(response => {
      if (response.success) {
        song.favorited = false;
        this.detectChanges();
        this.toastService.success('Song was removed from favorites.');
      } else {
        this.toastService.error('There was an error, try again later.');
      }
    });
  }

  formatViews(views) {
    
    if (views > 999) {
      views = views / 1000;
      views = views.toFixed(1).replace(/[.,]0$/, "");
      // console.log(views + 'K', "panda");
      return views + 'K';
    } else {
      return views;
    };

  }

  formatLove(love) {
    if (love > 999) {
        love = love / 1000;
        love = love.toFixed(1).replace(/[.,]0$/, "");
        // console.log(love + 'K', "cupid");
        return love + 'K';
    } else {
        return love;
    };
  };

  formatComments(comments) {
    if (comments > 999) {
        comments = comments / 1000;
        comments = comments.toFixed(1).replace(/[.,]0$/, "");
        // console.log(comments + 'K', "cupid");
        return comments + 'K';
    } else {
        return comments;
    };
  };


  selectRecording(item){
    this.router.navigate(['/d/listen/', item.recording_id]);
  }

  howOthersSingThis(id){
    if (this.isControlPressed){
      let url: string = environment.baseAPIUrl + '/admin/music/songs/' + id + '/lyrics';
      this.userService.getIsAdmin().subscribe(response => {
        if (response.success) {
          window.open(url);
        }   
      }, error => {
      });
      this.isControlPressed = false;
    } else {
      this.router.navigate([`/d/ListenOthersSing/${id}`]);
    }
  }
  
  navArtistProfile(id){
    this.navigate([`/d/artists/${id}`])
  }
}
