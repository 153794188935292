import { Injectable } from '@angular/core';

import { HttpClient , HttpParams} from  '@angular/common/http';

import { UtilService } from './util.service';


@Injectable({
  providedIn: 'root'
})
export class EmailNotificationsService { 

  constructor(private http: HttpClient, private util: UtilService) { }

  private urlComment = 'https://us-central1-singsnap-v2.cloudfunctions.net/eMessageComment';
  private urlLove = 'https://us-central1-singsnap-v2.cloudfunctions.net/eMessageLove';
  private urlUnLove = 'https://us-central1-singsnap-v2.cloudfunctions.net/eMessageUnLove';
  private urlReaction = 'https://us-central1-singsnap-v2.cloudfunctions.net/eMessageReaction';
  private urlRose = 'https://us-central1-singsnap-v2.cloudfunctions.net/eMessageRose';
  private urlRoseAnonymous = 'https://us-central1-singsnap-v2.cloudfunctions.net/eMessageRoseAnonymous';
  private urlFavorite = 'https://us-central1-singsnap-v2.cloudfunctions.net/eMessageFavorite';
  private urlFavoriteV2 = 'https://us-central1-singsnap-v2.cloudfunctions.net/eMessageFavoriteV2';
  private urlCommentFeaturedOn = 'https://us-central1-singsnap-v2.cloudfunctions.net/eMessageCommentFeaturedOn';
  private urlLoveFeaturedOn = 'https://us-central1-singsnap-v2.cloudfunctions.net/eMessageLoveFeaturedOn';
  private urlRoseFeaturedOn = 'https://us-central1-singsnap-v2.cloudfunctions.net/eMessageRoseFeaturedOn';
  private urlReactionFeaturedOn = 'https://us-central1-singsnap-v2.cloudfunctions.net/eMessageReactionFeaturedOn';
  private urlRoseAnonymousFeatureOn = 'https://us-central1-singsnap-v2.cloudfunctions.net/eMessageRoseAnonymousFeaturedOn';
  private urlRecordingInteractions = 'https://us-central1-singsnap-v2.cloudfunctions.net/eMessageInteractions';
  private urlRecordingInteractionsV2 = 'https://us-central1-singsnap-v2.cloudfunctions.net/eMessageInteractionsV2';
  private urlTopicInteractions = 'https://us-central1-singsnap-v2.cloudfunctions.net/emailTopicInteractions';
  private urlTopicReplyInteractions = 'https://us-central1-singsnap-v2.cloudfunctions.net/emailReplyTopicInteractions';
  private urlMessageBoardInteractions = 'https://us-central1-singsnap-v2.cloudfunctions.net/emailMessageBoardInteractions';
  private urlMessagesSent = 'https://us-central1-singsnap-v2.cloudfunctions.net/emailMessagesSent';
  private urlRecordingDuetLayers = 'https://us-central1-singsnap-v2.cloudfunctions.net/emailRecordingDuetLayers';
  private urlMentions = 'https://us-central1-singsnap-v2.cloudfunctions.net/emailMentions';
  private urlRecordingReplies = 'https://us-central1-singsnap-v2.cloudfunctions.net/emailRecordingCommentReplies';
  private urlSentSnaps = 'https://us-central1-singsnap-v2.cloudfunctions.net/emailSnaps';
  private urlDistributeTrinkets = 'https://us-central1-singsnap-v2.cloudfunctions.net/emailTrinkets';
  private urlSnapicon = 'https://us-central1-singsnap-v2.cloudfunctions.net/emailSnapicons';
  private urlDistributeGMG = 'https://us-central1-singsnap-v2.cloudfunctions.net/emailGMG';
  private urlMessageBoardPost_Interactions = '';
  

  emailCommentNotification(email, recipient_name, sender_name, recording_song_name, share_url){
    const body = new HttpParams()
    .append('email', this.util.crypt(email))
    .append('recipient_name', this.util.crypt(recipient_name))
    .append('sender_name', this.util.crypt(sender_name))
    .append('recording_song_name', recording_song_name)
    .append('share_url', share_url)
    this.http.post (this.urlComment,  body).subscribe ((res)=>{
      console.log ('response', res)
    })
  }

  emailCommentFeaturedOnNotification(email, recipient_name, sender_name, recording_song_name, share_url){
    const body = new HttpParams()
    .append('email', this.util.crypt(email))
    .append('recipient_name', this.util.crypt(recipient_name))
    .append('sender_name', this.util.crypt(sender_name))
    .append('recording_song_name', recording_song_name)
    .append('share_url', share_url)
    this.http.post (this.urlCommentFeaturedOn,  body).subscribe ((res)=>{
      console.log ('response', res)
    })
  }

  emailLoveNotification(email, recipient_name, sender_name, recording_song_name, share_url){
    const body = new HttpParams()
    .append('email', this.util.crypt(email))
    .append('recipient_name', this.util.crypt(recipient_name))
    .append('sender_name', this.util.crypt(sender_name))
    .append('recording_song_name', recording_song_name)
    .append('share_url', share_url)
    this.http.post (this.urlLove,  body).subscribe ((res)=>{
      console.log ('response', res)
    })
  }

  emailLoveFeaturedOnNotification(email, recipient_name, sender_name, recording_song_name, share_url){
    const body = new HttpParams()
    .append('email', this.util.crypt(email))
    .append('recipient_name', this.util.crypt(recipient_name))
    .append('sender_name', this.util.crypt(sender_name))
    .append('recording_song_name', recording_song_name)
    .append('share_url', share_url)
    this.http.post (this.urlLoveFeaturedOn,  body).subscribe ((res)=>{
      console.log ('response', res)
    })
  }

  emailUnLoveNotification(email, recipient_name, sender_name, recording_song_name, share_url){
    const body = new HttpParams()
    .append('email', this.util.crypt(email))
    .append('recipient_name', this.util.crypt(recipient_name))
    .append('sender_name', this.util.crypt(sender_name))
    .append('recording_song_name', recording_song_name)
    .append('share_url', share_url)
    this.http.post (this.urlUnLove,  body).subscribe ((res)=>{
      console.log ('response', res)
    })
  }

  emailReactionNotification(email, recipient_name, sender_name, recording_song_name, share_url, emoji){
    const body = new HttpParams()
    .append('email', this.util.crypt(email))
    .append('recipient_name', this.util.crypt(recipient_name))
    .append('sender_name', this.util.crypt(sender_name))
    .append('recording_song_name', recording_song_name)
    .append('share_url', share_url)
    .append('emoji', emoji)
    this.http.post (this.urlReaction,  body).subscribe ((res)=>{
      console.log ('response', res)
    })
  }

  emailReactionFeaturedOnNotification(email, recipient_name, sender_name, recording_song_name, share_url, emoji){
    const body = new HttpParams()
    .append('email', this.util.crypt(email))
    .append('recipient_name', this.util.crypt(recipient_name))
    .append('sender_name', this.util.crypt(sender_name))
    .append('recording_song_name', recording_song_name)
    .append('share_url', share_url)
    .append('emoji', emoji)
    this.http.post (this.urlReactionFeaturedOn,  body).subscribe ((res)=>{
      console.log ('response', res)
    })
  }

  emailRoseNotification(email, recipient_name, sender_name, recording_song_name, share_url){
    const body = new HttpParams()
    .append('email', this.util.crypt(email))
    .append('recipient_name', this.util.crypt(recipient_name))
    .append('sender_name', this.util.crypt(sender_name))
    .append('recording_song_name', recording_song_name)
    .append('share_url', share_url)
    this.http.post (this.urlRose,  body).subscribe ((res)=>{
      console.log ('response', res)
    })
  }

  emailRoseFeaturedOnNotification(email, recipient_name, sender_name, recording_song_name, share_url){
    const body = new HttpParams()
    .append('email', this.util.crypt(email))
    .append('recipient_name', this.util.crypt(recipient_name))
    .append('sender_name', this.util.crypt(sender_name))
    .append('recording_song_name', recording_song_name)
    .append('share_url', share_url)
    this.http.post (this.urlRoseFeaturedOn,  body).subscribe ((res)=>{
      console.log ('response', res)
    })
  }

  emailRoseNotificationAnonymous(email, recipient_name, sender_name, recording_song_name, share_url){
    const body = new HttpParams()
    .append('email', this.util.crypt(email))
    .append('recipient_name', this.util.crypt(recipient_name))
    .append('sender_name', this.util.crypt(sender_name))
    .append('recording_song_name', recording_song_name)
    .append('share_url', share_url)
    this.http.post (this.urlRoseAnonymous,  body).subscribe ((res)=>{
      console.log ('response', res)
    })
  }

  emailRoseFeaturedOnNotificationAnonymous(email, recipient_name, sender_name, recording_song_name, share_url){
    const body = new HttpParams()
    .append('email', this.util.crypt(email))
    .append('recipient_name', this.util.crypt(recipient_name))
    .append('sender_name', this.util.crypt(sender_name))
    .append('recording_song_name', recording_song_name)
    .append('share_url', share_url)
    this.http.post (this.urlRoseAnonymousFeatureOn,  body).subscribe ((res)=>{
      console.log ('response', res)
    })
  }

  emailFavoriteNotification(email, recipient_name, sender_name){
    const body = new HttpParams()
    .append('email', this.util.crypt(email))
    .append('recipient_name', this.util.crypt(recipient_name))
    .append('sender_name', this.util.crypt(sender_name))
    this.http.post (this.urlFavorite,  body).subscribe ((res)=>{
      console.log ('response', res)
    })
  }

  emailFavoriteNotificationV2(email, recipient_name, sender_name, sender_id, spic){
    const body = new HttpParams()
    .append('email', this.util.crypt(email))
    .append('recipient_name', this.util.crypt(recipient_name))
    .append('sender_name', this.util.crypt(sender_name))
    .append('sender_id', sender_id)
    .append('spic', spic)
    this.http.post (this.urlFavoriteV2,  body).subscribe ((res)=>{
      console.log ('response', res)
    })
  }

  emailRecInteractions(email, recipient_name, sender_name, sender_id, recording_song_name, share_url, rec_has_layers, comment_count, roses_count, rec_love, rec_reaction, rec_reaction_sent){
    const body = new HttpParams()
    .append('email', this.util.crypt(email))
    .append('recipient_name', this.util.crypt(recipient_name))
    .append('sender_name', this.util.crypt(sender_name))
    .append('sender_id', sender_id)
    .append('recording_song_name', recording_song_name)
    .append('share_url', share_url)
    .append('layers', rec_has_layers)
    .append('cc', comment_count)
    .append('rc', roses_count)
    .append('rl', rec_love)
    .append('rr', rec_reaction)
    .append('rrs', rec_reaction_sent)
    console.log ('body', body);
    this.http.post (this.urlRecordingInteractions,  body).subscribe ((res)=>{
      console.log ('response', res)
    })
  }

  emailRecInteractionsV2(email, recipient_name, sender_name, sender_id, recording_song_name, share_url, rec_has_layers, comment_count, roses_count, rec_love, rec_reaction, rec_reaction_sent, spic, vpic, vrec, rcom){
    const body = new HttpParams()
    .append('email', this.util.crypt(email))
    .append('recipient_name', this.util.crypt(recipient_name))
    .append('sender_name', this.util.crypt(sender_name))
    .append('sender_id', sender_id)
    .append('recording_song_name', recording_song_name)
    .append('share_url', share_url)
    .append('layers', rec_has_layers)
    .append('cc', comment_count)
    .append('rc', roses_count)
    .append('rl', rec_love)
    .append('rr', rec_reaction)
    .append('rrs', rec_reaction_sent)
    .append('spic', spic)
    .append('vpic', vpic)
    .append('vrec', vrec)
    .append('rcom', rcom)
    this.http.post (this.urlRecordingInteractionsV2,  body).subscribe ((res)=>{
      console.log ('response', res)
    })
  }




  emailTopicInteractions(email, recipient_name, sender_name, sender_id, topic_name, share_topicId, topic_fav, topic_love, reply_count){
    const body = new HttpParams()
    .append('email', this.util.crypt(email))
    .append('recipient_name', this.util.crypt(recipient_name))
    .append('sender_name', this.util.crypt(sender_name))
    .append('sender_id', sender_id)
    .append('topic_name', topic_name)
    .append('share_topicId', share_topicId)
    .append('topic_fav', topic_fav)
    .append('topic_love', topic_love)
    .append('reply_count', reply_count)
    this.http.post (this.urlTopicInteractions,  body).subscribe ((res)=>{
      console.log ('response', res)
    })
  }

  emailTopicReplyInteractions(email, recipient_name, sender_name, sender_id, topic_name, share_topicId, topic_reply_love, topic_reply_reaction, topic_reply_emoji){
    const body = new HttpParams()
    .append('email', this.util.crypt(email))
    .append('recipient_name', this.util.crypt(recipient_name))
    .append('sender_name', this.util.crypt(sender_name))
    .append('sender_id', sender_id)
    .append('topic_name', topic_name)
    .append('share_topicId', share_topicId)
    .append('topic_reply_love', topic_reply_love)
    .append('topic_reply_reaction', topic_reply_reaction)
    .append('topic_reply_emoji', topic_reply_emoji)
    this.http.post (this.urlTopicReplyInteractions,  body).subscribe ((res)=>{
      console.log ('response', res)
    })
  }

  emailMessageBoardInteractions(email, recipient_name, sender_name, sender_id, topic_name, share_topicId, topic_fav, topic_love, reply_count, topic_reply_mention, topic_reply_quote, topic_reply_love, topic_reply_reaction, topic_reply_emoji){
    const body = new HttpParams()
    .append('email', this.util.crypt(email))
    .append('recipient_name', this.util.crypt(recipient_name))
    .append('sender_name', this.util.crypt(sender_name))
    .append('sender_id', sender_id)
    .append('topic_name', topic_name)
    .append('share_topicId', share_topicId)
    .append('topic_fav', topic_fav)
    .append('topic_love', topic_love)
    .append('reply_count', reply_count)
    .append('reply_mention', topic_reply_mention)
    .append('topic_reply_quote', topic_reply_quote)
    .append('topic_reply_love', topic_reply_love)
    .append('topic_reply_reaction', topic_reply_reaction)
    .append('topic_reply_emoji', topic_reply_emoji)
    this.http.post (this.urlMessageBoardInteractions,  body).subscribe ((res)=>{
      console.log ('response', res)
      console.log ('sent to', email)
    })
  }

  emailMessageBoardPostReplyInteractions(email, recipient_name, sender_name, sender_id, topic_name, share_topicId, topic_reply_quote, topic_reply_love, topic_reply_reaction, topic_reply_emoji){
    const body = new HttpParams()
    .append('email', this.util.crypt(email))
    .append('recipient_name', this.util.crypt(recipient_name))
    .append('sender_name', this.util.crypt(sender_name))
    .append('sender_id', sender_id)
    .append('topic_name', topic_name)
    .append('share_topicId', share_topicId)
    .append('topic_reply_quote', topic_reply_quote)
    .append('topic_reply_love', topic_reply_love)
    .append('topic_reply_reaction', topic_reply_reaction)
    .append('topic_reply_emoji', topic_reply_emoji)
    this.http.post (this.urlMessageBoardPost_Interactions,  body).subscribe ((res)=>{
      console.log ('response', res)
    })
  }

  emailMessagesSent(email, recipient_name, sender_name, sender_id, convo_id, messages_count){
    const body = new HttpParams()
    .append('email', this.util.crypt(email))
    .append('recipient_name', this.util.crypt(recipient_name))
    .append('sender_name', this.util.crypt(sender_name))
    .append('sender_id', sender_id)
    .append('convo_id', convo_id)
    .append('messages_count', messages_count)
    this.http.post (this.urlMessagesSent,  body).subscribe ((res)=>{
      console.log ('response', res)
    })
  }

  emailrecordingDuetLayers(email, recipient_name, sender_name, sender_id, recording_id, title){
    const body = new HttpParams()
    .append('email', this.util.crypt(email))
    .append('recipient_name', this.util.crypt(recipient_name))
    .append('sender_name', this.util.crypt(sender_name))
    .append('sender_id', sender_id)
    .append('recording_id', recording_id)
    .append('title', title)
    this.http.post (this.urlRecordingDuetLayers,  body).subscribe ((res)=>{
      console.log ('response', res)
    })
  }

  emailMentions(email, recipient_name, sender_name, sender_id, source_name, share_sourceId,  topic_reply_mention, type){
    const body = new HttpParams()
    .append('email', this.util.crypt(email))
    .append('recipient_name', this.util.crypt(recipient_name))
    .append('sender_name', this.util.crypt(sender_name))
    .append('sender_id', sender_id)
    .append('source_name', source_name)
    .append('share_sourceId', share_sourceId)
    .append('reply_mention', topic_reply_mention)
    .append('type', type) 
    this.http.post (this.urlMentions,  body).subscribe ((res)=>{
      console.log ('response', res)
    })
  }
  

  emailRecordingCommentReplies(email, recipient_name, sender_name, sender_id, source_name, sourceId,  recording_reply_replies, recording_reply_loved, recording_reply_reaction, recording_reply_emoji){
    const body = new HttpParams()
    .append('email', this.util.crypt(email))
    .append('recipient_name', this.util.crypt(recipient_name))
    .append('sender_name', this.util.crypt(sender_name))
    .append('sender_id', sender_id)
    .append('source_name', source_name)
    .append('sourceId', sourceId)
    .append('reply_replies', recording_reply_replies)
    .append('reply_loved', recording_reply_loved) 
    .append('reply_reaction', recording_reply_reaction) 
    .append('reply_emoji', recording_reply_emoji) 
   this.http.post (this.urlRecordingReplies,  body).subscribe ((res)=>{
     console.log ('response', res)
   })
  }

  emailSentSnaps(email, recipient_name, sender_name, sender_id, snap_amount, anonymous){
    const body = new HttpParams()
    .append('email', this.util.crypt(email))
    .append('recipient_name', this.util.crypt(recipient_name))
    .append('sender_name', this.util.crypt(sender_name))
    .append('sender_id', sender_id)
    .append('amount', snap_amount)
    .append('anonymous', anonymous)
   this.http.post (this.urlSentSnaps,  body).subscribe ((res)=>{
     console.log ('response', res)
   })
  }

  emailDistributeTrinkets(email, recipient_name, sender_name, sender_id, trinket_name, trinket_icon, trinket_photo, trinket_amount, anonymous){
    const body = new HttpParams()
    .append('email', this.util.crypt(email))
    .append('recipient_name', this.util.crypt(recipient_name))
    .append('sender_name', this.util.crypt(sender_name))
    .append('sender_id', sender_id)
    .append('tn', trinket_name)
    .append('ti', trinket_icon)
    .append('tp', trinket_photo)
    .append('ta', trinket_amount)
    .append('anon', anonymous)
   this.http.post (this.urlDistributeTrinkets,  body).subscribe ((res)=>{
     console.log ('response', res)
   })
  }

  emailSnapicon(email, recipient_name, sender_name, sender_id, snapicon_name, snapicon_icon, anonymous){
    const body = new HttpParams()
    .append('email', this.util.crypt(email))
    .append('recipient_name', this.util.crypt(recipient_name))
    .append('sender_name', this.util.crypt(sender_name))
    .append('sender_id', sender_id)
    .append('sn', snapicon_name)
    .append('si', snapicon_icon)
    .append('anon', anonymous)
   this.http.post (this.urlSnapicon,  body).subscribe ((res)=>{
     console.log ('response', res)
   })
  }

  emailDistributeGMG(email, recipient_name, sender_name, sender_id, gmg_name, gmg_photo, gmg_amount, gmgName_and_Amount, membershipEndDate, anonymous){
    const body = new HttpParams()
    .append('email', this.util.crypt(email))
    .append('recipient_name', this.util.crypt(recipient_name))
    .append('sender_name', this.util.crypt(sender_name))
    .append('sender_id', sender_id)
    .append('gn', gmg_name)
    .append('gp', gmg_photo)
    .append('ga', gmg_amount)
    .append('gna', gmgName_and_Amount)
    .append('med', membershipEndDate)
    .append('anon', anonymous)
   this.http.post (this.urlDistributeGMG,  body).subscribe ((res)=>{
     console.log ('response', res)
   })
  }

}