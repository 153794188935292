import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, ErrorHandler} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { HttpClientModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
//import 'webrtc-adapter';
//import 'hammerjs';
//import { FormWizardModule } from 'angular2-wizard';
//import { NguCarouselModule } from '@ngu/carousel';
//import { SwiperModule } from 'angular2-useful-swiper';
// import {
//   SocialLoginModule,
//   SocialAuthServiceConfig,
//   //LoginOpt,
// } from 'angularx-social-login';
// import {
//   GoogleLoginProvider,
//   FacebookLoginProvider,
// } from 'angularx-social-login';

//import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
//import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

import { CoreModule } from './core';
import { SharedModule } from './shared';
import { SharedIconsModule } from './shared/icons/icons.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { AboutUsComponent } from './components/website/about-us/about-us.component';
import { PricingComponent } from './components/website/pricing/pricing.component';
import { SupportPageComponent } from './components/website/support-page/support-page.component';
import { WebsiteHomeComponent } from './components/website/website-home/website-home.component';
//import { BlogComponent } from './components/website/blog/blog.component';
//import { HeaderComponent } from './components/website/blog/header/header.component';
import { FooterComponent } from './components/website/main/footer/footer.component';
//import { FeedComponent } from './components/website/blog/feed/feed.component';
//import { GeneralComponent } from './components/website/blog/feed/posts/general/general.component';
//import { NewsComponent } from './components/website/blog/feed/posts/news/news.component';
//import { TrendingComponent } from './components/website/blog/feed/posts/trending/trending.component';
import { MainComponent } from './components/website/main/main.component';
import { LoginComponent } from './components/website/login/login.component';
import { SignUpComponent } from './components/website/sign-up/sign-up.component';
//import { PostComponent } from './components/website/blog/post/post.component';
import { ShopComponent } from './components/shop/shop.component';
import { ShopHomeComponent } from './components/shop/shop-home/shop-home.component';
import { ShopProductComponent } from './components/shop/shop-product/shop-product.component';
import { ShopCategoryComponent } from './components/shop/shop-category/shop-category.component';
import { ShopPaymentsComponent } from './components/shop/shop-payments/shop-payments.component';
import { ShopOrdersComponent } from './components/shop/shop-orders/shop-orders.component';
import { ShoppingCartService } from './components/shop/services/shopping-cart.service';
// import {
//   MatDatepickerModule,
//   MatNativeDateModule,
//   MatProgressSpinnerModule,
//   MatSliderModule,
// } from '@angular/material';
import { InfluencerComponent } from './components/website/influencer/influencer.component';
//import { MomentModule } from 'ngx-moment';
import { CopyrightComponent } from './components/website/policies/copyright/copyright.component';
import { TermsConditionsComponent } from './components/website/policies/terms-conditions/terms-conditions.component';
import { CopyOfConductComponent } from './components/website/policies/copy-of-conduct/copy-of-conduct.component';
import { PrivacyPolicyComponent } from './components/website/policies/privacy/privacy.component';
import { DashboardReModule } from './dashboard-re';
import { LoginAuthGuardService } from '@app/core';
//import { NoopInterceptor } from './core/interceptor';
import { ListenReModule } from './listen-re';
import { SingReModule } from './sing-re';
import { EnvSwitcherComponent } from './env-switcher/env-switcher.component';
import { LegacyViewerComponent } from './legacy-viewer/legacy-viewer.component';
import { UserProfileModule } from './user-profile/user-profile.module';
import { SettingsModule } from './settings/settings.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsModule } from './notifications/notifications.module';
import { MyRecordingsComponent } from './my-recordings/my-recordings.component';
import { environment } from './../environments/environment';
import { OverlayModule } from '@angular/cdk/overlay';
import { ServiceWorkerModule, SwPush } from '@angular/service-worker';
import { MyFavoritesComponent } from './my-favorites/my-favorites.component';
import { PlayPlaylistComponent } from './play-playlist/play-playlist.component';
import { SessionCarouselComponent } from './components/website/session-carousel/session-carousel.component';
import { ResetPasswordComponent } from './components/website/reset-password/reset-password.component';
import { RecoveryComponent } from './components/website/recovery/recovery.component';
import { ResetCodeComponent } from './components/website/reset-code/reset-code.component';
import { ConfirmResetComponent } from './components/website/confirm-reset/confirm-reset.component';
import { SignupCompleteComponent } from './components/website/signup-complete/signup-complete.component';
import { SocialSignupFormComponent } from './components/website/social-signup-form/social-signup-form.component';
import { AuthMenuComponent } from './components/website/auth-menu/auth-menu.component';
import { AuthListenComponent } from './components/website/auth-listen/auth-listen.component';
import { AuthSingComponent } from './components/website/auth-sing/auth-sing.component';
import { AuthHelpComponent } from './components/website/auth-help/auth-help.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { CodeOfConductComponent } from './code-of-conduct/code-of-conduct.component';
import { MyRecordingsRecycleBinComponent } from './my-recordings-recycle-bin/my-recordings-recycle-bin.component';
import { ChatComponent } from './chat/chat.component';
import { HelpComponent } from './help/help.component';
//import { MainFooterComponent } from './main-footer/main-footer.component';
import { NgxIndexedDBModule , DBConfig} from 'ngx-indexed-db';
import { SuperrecorderComponent } from './superrecorder/superrecorder.component';
import { GlobalHttpInterceptorService } from './core/services/global-http-interceptor.service';
import { PartnersComponent } from './partners/partners.component';
import { SuperremixerComponent } from './superremixer/superremixer.component';
import { NgxGlideModule } from 'ngx-glide';
import { MySingMoreComponent } from './my-sing-more/my-sing-more.component';
import { MyListenMoreComponent } from './my-listen-more/my-listen-more.component';
import { SeeMoreComponent } from './see-more/see-more.component';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { ArtistSeemoreComponent } from './artists/artist-seemore/artist-seemore.component';
import { AdsenseModule } from 'ng2-adsense';
import { OpenViduAngularConfig, OpenViduAngularModule } from 'openvidu-angular';

const config: OpenViduAngularConfig = {
    production: environment.production
};

Bugsnag.start(environment.bugSnagId);
// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
 return new BugsnagErrorHandler()
}

const dbConfig: DBConfig  = {
  name: 'failedUploadsDB',
  version: 1,
  objectStoresMeta: [{
    store: 'uploads',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'date', keypath: 'date', options: { unique: false } },
      { name: 'song', keypath: 'song', options: { unique: false } },
      { name: 'meta', keypath: 'meta', options: { unique: false } },
      { name: 'recording', keypath: 'recording', options: { unique: false } },
      { name: 'uuid', keypath: 'uuid', options: { unique: false } },
      { name: 'device', keypath: 'device', options: { unique: false } },
      { name: 'part', keypath: 'part', options: { unique: false } },
      { name: 'mmsb', keypath: 'mmsb', options: { unique: false } },
      { name: 'info', keypath: 'info', options: { unique: false } },
      { name: 'private', keypath: 'private', options: { unique: false } },
      { name: 'members_only', keypath: 'members_only', options: { unique: false } },
      { name: 'allow_loves', keypath: 'allow_loves', options: { unique: false } },
      { name: 'allow_comments', keypath: 'allow_comments', options: { unique: false } },
      { name: 'track_views', keypath: 'track_views', options: { unique: false } },
      { name: 'allow_share', keypath: 'allow_share', options: { unique: false } },
      { name: 'allow_duets', keypath: 'allow_duets', options: { unique: false } },
      { name: 'view_on_profile', keypath: 'view_on_profile', options: { unique: false } },
      { name: 'offensive', keypath: 'offensive', options: { unique: false } },
    ]
  },
  {
    store: 'bannerHide',
    storeConfig: { keyPath: 'id', autoIncrement: false },
    storeSchema: [
      { name: 'hide', keypath: 'hide', options: { unique: false } },
    ]
  }

  ]
};
// const fbLoginOptions: LoginOpt = {
//   scope: 'pages_messaging,pages_messaging_subscriptions,email,pages_show_list,manage_pages',
//   return_scopes: true,
//   enable_profile_selector: true,
// }; // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11

// const googleLoginOptions: LoginOpt = {
//   scope: 'profile email',
// }; // https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2clientconfig

// const config = new SocialAuthServiceConfig([
//   {
//     id: GoogleLoginProvider.PROVIDER_ID,
//     provider: new GoogleLoginProvider(environment.social.googleAuthClientId),
//   },
//   {
//     id: FacebookLoginProvider.PROVIDER_ID,
//     provider: new FacebookLoginProvider(environment.social.facebookAppId),
//   },
// ]);

// export function provideConfig() {
//   return config;
// }

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  handlers: ['click-rail', 'drag-thumb', 'wheel', 'touch']
  // suppressScrollX: true
};

@NgModule({
    declarations: [
        AppComponent,
        PrivacyPolicyComponent,
        AboutUsComponent,
        PricingComponent,
        SupportPageComponent,
        WebsiteHomeComponent,
        //BlogComponent,
        //HeaderComponent,
        FooterComponent,
        //FeedComponent,
        //GeneralComponent,
        //NewsComponent,
        //TrendingComponent,
        MainComponent,
        LoginComponent,
        SignUpComponent,
        //PostComponent,
        ShopComponent,
        ShopHomeComponent,
        ShopProductComponent,
        ShopCategoryComponent,
        ShopPaymentsComponent,
        ShopOrdersComponent,
        InfluencerComponent,
        CopyrightComponent,
        TermsConditionsComponent,
        CopyOfConductComponent,
        EnvSwitcherComponent,
        LegacyViewerComponent,
        MyRecordingsComponent,
        MyFavoritesComponent,
        PlayPlaylistComponent,
        SessionCarouselComponent,
        ResetPasswordComponent,
        RecoveryComponent,
        ResetCodeComponent,
        ConfirmResetComponent,
        SignupCompleteComponent,
        SocialSignupFormComponent,
        AuthMenuComponent,
        AuthListenComponent,
        AuthSingComponent,
        AuthHelpComponent,
        TermsComponent,
        PrivacyComponent,
        CodeOfConductComponent,
        MyRecordingsRecycleBinComponent,
        ChatComponent,
        HelpComponent,
        SuperrecorderComponent,
        PartnersComponent,
        SuperremixerComponent,
        MySingMoreComponent,
        MyListenMoreComponent,
        SeeMoreComponent,
        ArtistSeemoreComponent,
        //MainFooterComponent,
    ],
    imports: [
        // angular
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        RouterModule,
        OverlayModule,
        // 3rd party
        NgxGlideModule,
        PerfectScrollbarModule,
        // FormWizardModule,
        InfiniteScrollModule,
        NgxIndexedDBModule.forRoot(dbConfig),
        NgxGoogleAnalyticsModule.forRoot('G-P1C9VPFBQN'),
        NgxGoogleAnalyticsRouterModule,
        //SwiperModule,
        //SocialLoginModule,
        //Ng2ScrollimateModule,
        // MatSliderModule,
        // MatDatepickerModule,
        // MatNativeDateModule,
        // MatProgressSpinnerModule,
        //Angular2SocialLoginModule,
        //MomentModule,
        NgbModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        }),
        //NguCarouselModule,
        //LoadingBarHttpClientModule,
        //LoadingBarRouterModule,
        // app
        CoreModule,
        SharedModule,
        SharedIconsModule,
        DashboardReModule,
        ListenReModule,
        SingReModule,
        UserProfileModule,
        SettingsModule,
        NotificationsModule,
        InlineSVGModule.forRoot(),
        AdsenseModule.forRoot({
        // adClient: 'ca-pub-4776037543768273',
        // adSlot: 9989671476,
        }),
        //OpenViduAngularModule.forRoot(config),
    ],
    providers: [
        LoginAuthGuardService,
        ShoppingCartService,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: GlobalHttpInterceptorService,
            multi: true,
        },
        { provide: ErrorHandler,
            useFactory: errorHandlerFactory,
        }
        // {
        //   provide: 'SocialAuthServiceConfig',
        //   useValue: {
        //     autoLogin: false,
        //     providers: [
        //       {
        //         id: GoogleLoginProvider.PROVIDER_ID,
        //         provider: new GoogleLoginProvider(
        //           'clientId'
        //         ),
        //       },
        //       {
        //         id: FacebookLoginProvider.PROVIDER_ID,
        //         provider: new FacebookLoginProvider('clientId'),
        //       },
        //     ],
        //   } as SocialAuthServiceConfig
        // }
        // {
        //   provide: HTTP_INTERCEPTORS,
        //   useClass: NoopInterceptor,
        //   multi: true,
        // },
        // {
        //   provide: AuthServiceConfig,
        //   useFactory: provideConfig,
        // },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  readonly VAPID_PUBLIC_KEY = environment.publicVapidKey;

  constructor() {
    this.subscribeToNotifications();
  }


  subscribeToNotifications() {
    // this.swPush
    //   .requestSubscription({
    //     serverPublicKey: this.VAPID_PUBLIC_KEY,
    //   })
    //   .then(sub => this.newsletterService.addPushSubscriber(sub).subscribe())
    //   .catch(err => console.error('Could not subscribe to notifications', err));
  }
}
