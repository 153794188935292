<div class="container my-recordings">
<div class="row">
  <div class="col-12 col-lg-9">
    <div class="row">
        <div class="col-3">
           <h1 class="big-title">My Recordings</h1>
           <p>Deleted in last 10 days</p>
           <p></p>
        </div>

        <div class="col-3">
          <div class="ss-tabs">
              <div class="ss-tabs-item" [ngClass]="{'active': !true}" [routerLink]="['/d/my-recordings']"
              >
                Uploaded
              </div>
              <div class="ss-tabs-item" [ngClass]="{'active': true}" [routerLink]="['/d/my-recordings-recycle-bin']"
              >
                Recycle Bin
              </div>
          </div>
          </div>
    </div>
  </div>
  <div class="col-12 col-sm-9">
      <app-featured-list
          [showHeader]="false"
          [featuredList]="recordings"
          [showSort]="false"
          [loading]="data.rqing"
          skeletonType="myRecordingsRecycleBin"
          [scrollable]="false"
          [isInfiniteScroll]="false"
          (onScrollEnd)="onScrollEndHandle(data, $event)"
          (onOrderBy)="resetDataObj(data);onScrollEndHandle(data, $event)">
          <h2 class="featured-list--header">RECYCLE BIN</h2>
          
          <ng-template #itemContent let-item>
              <div class="col-auto px-0 align-items-center">
                  <div class="featured-list--item--poster"
                       [ngStyle]="{'background-image': 'url('+item?.cover+')'}">
                      <img [src]="item?.photo" alt="" class="img-fluid">
                  </div>
              </div>
              <div class="col featured-item--body align-items-center">
                  <h1>{{item?.song.title | truncateText:60:'...'}} <br>
                      <span>
                          {{item?.song.artist.name | truncateText:50:'...'}}
                      </span>
                  </h1>
              </div>
              <div>
              <span style="font-size: 12px;">
                <i>Created At:</i>
                {{item?.created_at | dateFormat | date:'MMM d, yyyy h:mma' | dateAmpm}}
              </span>
              <br>
              <span style="font-size: 12px;">
                <i>Deleted At:</i>
                {{item?.updated_at | dateFormat | date:'MMM d, yyyy h:mma' | dateAmpm}}
              </span>
            </div>
              <div class="col-auto featured-list--item-action align-items-center">
                  <app-more-dot-menu title='Actions'>
                  <div class="dropdown-menu-content dropdown-menu-profile-recordings">
                      <div (click)="restoreRecording(item)">Restore</div>
                  </div>
                  </app-more-dot-menu>
              </div>
          </ng-template>
      </app-featured-list>
      <div  id="pageNavContainer" style="margin-bottom: 13px;"><button (click)="getPrevRecordings()">&lt; PREV</button><p>Page: {{currentPage}} / {{setTotalPages()}}</p><button (click)="getNextRecordings()">NEXT &gt;</button></div>
  </div>

  
</div>


</div>


