
import {filter,  tap, takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { AppFacade } from '@app/app.facade.service';
import { Subject } from 'rxjs';
import {
  ProfileService,
  EventService,
  RecordingService,
  UsersService,
  MessageBoardService,
} from '@app/core';

@Component({
  selector: 'app-dashboard-re',
  templateUrl: './dashboard-re.component.html',
  styleUrls: ['./dashboard-re.component.scss'],
})
export class DashboardReComponent implements OnInit {
  @ViewChild(PerfectScrollbarDirective)
  directiveRef?: PerfectScrollbarDirective;
  @ViewChild('ps') ps;
  @ViewChild('snap', { static: true }) private snapModal;
  @ViewChild('gift', { static: true }) private giftModal;
  @ViewChild('blocked', { static: true }) private blockedModal;
  @ViewChild('socialShare', { static: true }) private socialShare;

  playlists;
  singTabActive = false;
  listenTabActive = false;
  config = {};
  nightmode$ = this.appFacade.nightmode$.pipe(
    tap(nightmode => {
      if (nightmode) {
        this.renderer.addClass(document.body, 'night-theme');
      } else {
        this.renderer.removeClass(document.body, 'night-theme');
      }
    })
  );

  private unsubscribeAll: Subject<any> = new Subject();

  constructor(
    private router: Router,
    private recordingService: RecordingService,
    private pService: ProfileService,
    private appFacade: AppFacade,
    private events: EventService,
    private usersService: UsersService,
    private messageBoardService: MessageBoardService,
    private renderer: Renderer2
  ) {
    // window.addEventListener('keydown', (e) => {
    //   // if(e.keyCode == 32) {
    //   //   e.stopPropagation();
    //   //   e.preventDefault();
    //   // }
    // })
    router.events.subscribe(val => {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      //this.directiveRef.scrollToTop();
    });
  }

  async ngOnInit() {
    this.handleRoutesChange();
    this.events.evtScrollTop
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(() => {
        //this.ps.nativeElement.scrollTop = 0;
      });

    this.events.evtSendSnapCredit
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(user => {
        this.snapModal.open(user);
      });

    this.events.evtSendGift
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(user => {
        this.giftModal.open(user);
      });

    this.events.evtShowBlockedNotify
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(user => {
        this.blockedModal.open(user);
      });

    this.events.evtSocialShare
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(data => {
        this.socialShare.open(data);
      });

    // const recordings = await this.recordingService.getRecentlyRecorded().toPromise();
    // this.socialShare.open({ recording: recordings.data[0] });

    // const users = await this.usersService.getUsers().toPromise();
    // this.socialShare.open({ user: users.data[0] });

    // const threads = await this.messageBoardService.getCategories().toPromise();
    // this.socialShare.open({ thread: threads.data[0] });
  }

  private handleRoutesChange() {
    this.router.events.pipe(
      filter((e: any) => e instanceof NavigationStart))
      .subscribe((e: NavigationStart) => {
        this.singTabActive = false;
        this.listenTabActive = false;
        const url = e.url;
        if (
          url.startsWith('/dashboard/listen') ||
          url.startsWith('/dashboard/member')
        ) {
          this.listenTabActive = true;
        }
        if (url.startsWith('/dashboard/sing')) {
          this.singTabActive = true;
        }
      });
  }

  public loadMyRecordings() {
    this.recordingService.reloadUserRecordings.emit({ logedUser: true });
  }

  dashboardClicked() {
    this.pService.openDuetsAction.emit({ actionType: 'play' });
  }
}
