
import {takeUntil} from 'rxjs/operators';
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  NgZone
} from '@angular/core';
import { RecordingService } from '@app/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { concat, differenceBy, uniqBy, map } from 'lodash';
import Bugsnag from '@bugsnag/js';
import { ReactionComponent } from '@app/shared/reaction/reaction.component';
import {
  EventService,
  PlaylistService,
  UsersService,
  HelperService,
  UtilService,
  ProfileService,
} from '@app/core/services';
import { ToastrService } from 'ngx-toastr';
declare var gtag;

@Component({
  selector: 'app-play-playlist',
  templateUrl: './play-playlist.component.html',
  styleUrls: ['./play-playlist.component.scss'],
})
export class PlayPlaylistComponent implements OnInit, OnDestroy {
  @ViewChild('playlist', { static: true }) private playlistElem;
  @ViewChild('confirmation', { static: true }) private confirmationModal;
  @ViewChild('goldcontent', { static: true }) private goldModal;
  @ViewChild('snap', { static: true }) private snapModal;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  PAGE_SIZE = 10;
  recording;
  comments = [];
  commentsTotal = 0;
  recordingId: any;
  reactionList = [];
  reactions = [];
  favoriting = null;
  showReplay = false;
  currentCommentPage = 1;
  loadingComments = true;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  lastPage = 1;
  channel = null;
  playlistId = null;
  shuffle = false;
  continuousPlay = false;
  ids = [];
  current = 0;
  autoPlayed = 0;
  commentFocus = false;
  totalRecordings = 0;

  recordings = {
    items: [],
    current: 1,
    loadedAll: false,
    loading: false,
  };
  recordingsLayers = {
    items: [],
    current: 1,
    loadedAll: false,
    loading: false,
  };
  recordingLayersAll: any;
  backlink;
  firstPlay = true;
  loggedInIsGold = true;
  @ViewChild('listenReaction') listenReaction: ReactionComponent;

  private favoriteSource = new Subject<any>();
  favorite$ = this.favoriteSource.asObservable();

  constructor(
    private recordingService: RecordingService,
    private playlistService: PlaylistService,
    public profileService: ProfileService,
    private usersService: UsersService,
    private utilService: UtilService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private toastService: ToastrService,
    private events: EventService,
    private helpers: HelperService,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.playlistId = params.id;
      console.log('loading recordings')
      this.loadRecordings();
    });
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams['backToContest']) {
        this.backlink = '/d/contests/' + queryParams['backToContest'];
      }
    });
  }

  public navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }


  ngOnInit() {
    this.getRecordingCount(this.playlistId);
    let loggedIn = this.utilService.getloggedUserData();
    this.loggedInIsGold = loggedIn.gold;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  loadRecordings() {
    this.recordings.loading = true;
    this.playlistService
      .getPlaylistRecordings(
        this.playlistId,
        this.recordings.current,
        this.PAGE_SIZE
      )
      .subscribe(result => {
        console.log('got more recordings')
        console.log(result)
        this.recordings.items.push(...result.data);
        this.refreshIds();
        this.recordings.current++;
        this.recordings.loading = false;
        this.recordings.loadedAll = !result.next_page_url;
        if (this.recordings.items && this.recordings.items.length > 0) {
          if(this.firstPlay) {
            this.onPlayRecording(this.recordings.items[0]);
            this.firstPlay = false;
          }
        }
      });
  }

  getRecordingLayers(id){
    this.recordingsLayers = {
      items: [],
      current: 1,
      loadedAll: false,
      loading: false,
    };
    this.recordingService.getRecordingLayers(id).subscribe(result => {
       this.recordingsLayers.items.push(...result.data);
      });
   }


  handleSing(song) {
    if (song.gold && !this.profileService.user.gold) {
      this.goldModal.open();
      return;
    }
    this.router.navigate(['/recorder', song.id]);
  }

  handleDuet(duet) {
    if (duet.song.gold && !this.profileService.user.gold) {
      this.goldModal.open();
      return;
    }
    this.router.navigate(['/duet-recorder', duet.id]);
  }

  handleRecordingChange() {
    console.log('handle recording change')
    // this.channel = this.pusherService.pusher.subscribe(
    //   'private-recording-' + this.recordingId
    // );
    // this.channel.bind('pusher:subscription_succeeded', function() {});
    // this.channel.bind('new-message', function(data) {});

    this.getComments();
    this.getRecording();

    this.favorite$.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(({ success, model, message }) => {
        this.favoriting = null;
        if (success === 'true') {
          this.recording.favorited = !this.recording.favorited;
          this.recording.love_count = model.love_count;
        }
      });
  }

  handleRecordingReaction() {
    if(typeof this.listenReaction != 'undefined'){
      this.listenReaction.emoji$.pipe(
        takeUntil(this.ngUnsubscribe))
        .subscribe(emojiReaction => {
          console.log('reaction', emojiReaction);
          this.recordingService
            .reaction(this.recordingId, emojiReaction.id).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(({ success, reactions }) => {
              if (success) {
                this.reactionList = reactions.concat([]);
                this.reactions = reactions.filter(reaction => {
                  return reaction.count > 0;
                });
                if (this.reactions.length > 1) {
                  this.reactions.sort((a, b) => {
                    return b.count - a.count;
                  });
                }
              }
            });
        });
    }
  }

  getRecording() {
    this.getRecordingLayers(this.recordingId);
    this.recordingService
      .get(this.recordingId).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(
        recording => {
          this.reactionList = recording.reactions.concat([]);
          this.reactions = recording.reactions.filter(reaction => {
            return reaction.count > 0;
          });
          if (this.reactions.length > 1) {
            this.reactions.sort((a, b) => {
              return b.count - a.count;
            });
          }
          this.recording = recording;
          this.recording.icons = this.utilService.getRecordingPrivacyIcons(
            recording
          );
          if (this.recording.users) {
            this.recording.users = uniqBy(this.recording.users, 'id');
          }
          setTimeout(() => this.handleRecordingReaction());
          gtag('event', 'listen', {
            action: 'Play Recording Playlist',
            recId: this.recordingId.toString(),
            recSongId: this.recording.song.id.toString(),
            songId: this.recording.song.id.toString()
            });
        },
        err => {
          console.log(err);
          if (err.status === 403) {
            let msg = "Apologies! You are blocked from viewing this user's recordings."
            this.toastService.error(msg  
            );
            if (this.current === this.ids.length - 1) {
              console.log('show');
              this.showReplay = true;
            } else {
              this.onNext();
            }
            Bugsnag.notify('Playlist Player error: ' + msg);
          } else {
            let msg = err.toString();
            Bugsnag.notify('Playlist Player error: ' + msg);
          }
        }
      );
  }

  toggleFavorite() {
    this.favoriting = '';
    (this.recording.favorited
      ? this.unFavoriteRecording()
      : this.favoriteRecording()
    ).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.favoriteSource.next(data);
      });
  }

  favoriteRecording() {
    return this.recordingService.fav(this.recordingId);
  }

  unFavoriteRecording() {
    return this.recordingService.unfav(this.recordingId);
  }

  toggleLove() {
    this.favoriting = true;
    (this.recording.loved ? this.unLoveRecording() : this.loveRecording()).pipe(
        takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            this.recording.loved = data.model.loved;
            this.recording.stats.love = data.model.stats.love;
            this.favoriting = false;
            if (data.success) {
            }
            // this.onLove.emit({
            //     success: true,
            //     data: data
            // });
        });
}

loveRecording() {
    return this.recordingService.love(this.recording.id);
}

unLoveRecording() {
    return this.recordingService.unlove(this.recording.id);
}


  getComments() {
    console.log('getting comments');
    if (this.lastPage >= this.currentCommentPage) {
      this.loadingComments = true;
      this.recordingService
        .getComments(this.recordingId, this.currentCommentPage, 10).pipe(
        takeUntil(this.ngUnsubscribe))
        .subscribe(this.processData.bind(this));
    }
  }

  onEditorFocused(e) {
    this.commentFocus = true;
  }

  onEditorBlurred(e) {
    this.commentFocus = false;
  }

  processData(data) {
    this.currentCommentPage++;
    this.lastPage = data.last_page;
    this.commentsTotal = data.total;

    let newComments = data.data;

    if (this.comments.length > 0) {
      newComments = differenceBy(data.data, this.comments, 'comment_id');
    }
    this.comments = concat(this.comments, newComments);
    this.loadingComments = false;
  }

  getFavoriteTooltip(recording) {
    return recording && recording.favorited
      ? 'Unlove this recording.'
      : 'Love this recording.';
  }

  onLoveHandle($event) {
    this.toggleFavorite();
  }

  refreshIds() {
    this.ids = map(this.recordings.items, 'id');
  }

  onPlayRecording(recording, indx=null) {
    console.log('onPlayRecording')
    this.continuousPlay = true;
    if(indx){
      this.current = indx;
      if (this.current === this.ids.length - 1){
        this.loadRecordings();
      }
    } else {
      this.current = this.ids.indexOf(recording.id);
    }
    this.playRecording();
  }

  playRecording() {
    console.log('play recording')
    this.recordingId = undefined;
    this.recording = null;
    setTimeout(() => {
      this.recordingId = this.recordings.items[this.current].id;
      this.handleRecordingChange();
    }, 100);
  }

  onShuffle(value) {
    this.shuffle = value;
    if (this.shuffle) {
      const newList = [this.recordings.items[this.current]];
      this.recordings.items.splice(this.current, 1);
      newList.push(...this.utilService.shuffleArray(this.recordings.items));
      this.recordings.items = newList;
      this.refreshIds();
      this.current = 0;
      console.log(this.ids);
    }
  }

  onReplay() {
    this.showReplay = false;
    this.current = 0;
    this.playRecording();
  }

  onPrev() {
    this.continuousPlay = false;
    if (this.current === 0) {
      return;
    }
    this.current--;
    this.playRecording();
  }

  onNext(continuousPlay = true) {
    this.showReplay = false;
    if (this.commentFocus) {
      return; 
    }
    this.continuousPlay = continuousPlay;
    if (this.current === this.ids.length - 1) {
      return;
    }

    this.current++;
   
    // if (this.continuousPlay) {
    //   this.autoPlayed++;
    // } else {
    //   this.autoPlayed = 0;
    // }

      if (this.current === this.ids.length - 1){
      // this.confirmationModal
      //   .open('CONFIRM', 'Do you want to continue playing the playlist?')
      //   .then(result => {
      //     if (result === 'ok') {
      //       this.autoPlayed = 0;
      //       this.playRecording();
      //     }
      //   });
       this.loadRecordings();
      //  this.autoPlayed = 0;
       this.playRecording();
    } else {
      this.playRecording();
    }
  }

  onAddtoPlaylist() {
    this.playlistElem.open(this.recordingId);
  }

  onRemoveFromPlaylist(pivot_id){
    this.playlistService.deleteRecordingFromPlaylist(this.playlistId, pivot_id).subscribe(result => {
    this.recordings.items = this.recordings.items.filter(x => x.pivot_id !== pivot_id);
    });
  }

  onProfile(user) {
    this.openLink('/d/profile/' + user.id + '/info');
  }

  openLink(url) {
    window.open(window.location.origin + '/#' + url);
  }

  detectChanges() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  async subscribeUser(recording) {
    const recordingList = [this.recordings.items];
    this.helpers.subscribeUser(recording, recordingList);
  }

  async unsubscribeUser(recording) {
    const recordingList = [this.recordings.items];
    this.helpers.unsubscribeUser(recording, recordingList);
  }

  openSnapModal() {
    this.snapModal.open(this.recording.users[0]);
  }

  sendGift() {
    this.events.onSendGift(this.recording.users[0]);
  }

  sendSnap() {
    this.events.onSendSnapCredit(this.recording.user);
  }

  shareRecording(recording) {
    this.events.onSocialShare({ recording });
  }

  formatViews(views) {
    
    if (views > 999) {
      views = views / 1000;
      views = views.toFixed(1).replace(/[.,]0$/, "");
      // console.log(views + 'K', "panda");
      return views + 'K';
    } else {
      return views;
    };

  };

  formatLove(love) {
    if (love > 999) {
        love = love / 1000;
        love = love.toFixed(1).replace(/[.,]0$/, "");
        // console.log(love + 'K', "cupid");
        return love + 'K';
    } else {
        return love;
    };
  };

  formatComments(comments) {
    if (comments > 999) {
        comments = comments / 1000;
        comments = comments.toFixed(1).replace(/[.,]0$/, "");
        // console.log(comments + 'K', "cupid");
        return comments + 'K';
    } else {
        return comments;
    };
  };

  selectRecording(item){
    this.router.navigate(['/d/listen/', item.recording_id]);
  }

  howOthersSingThis(id){
    this.navigate([`/d/ListenOthersSing/${id}`]);
  }

  navArtistProfile(id){
    this.navigate([`/d/artists/${id}`])
  }
  getRecordingCount(id){
    this.playlistService.getPlaylistDetail(id).subscribe(response => {
      this.totalRecordings =  response.stats.recordings;
    });
  }

}
