import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { Observable, Subject } from 'rxjs';
@Directive()
@Injectable({
  providedIn: 'root',
})
export class EventService {
  @Output() evtSelectUser: EventEmitter<any> = new EventEmitter<any>();
  @Output() evtUpdateUserProfile: EventEmitter<any> = new EventEmitter<any>();
  @Output() evtToggleContact: EventEmitter<boolean> = new EventEmitter();
  @Output() evtStartConversation: EventEmitter<any> = new EventEmitter();
  @Output() evtArchiveConversation: EventEmitter<any> = new EventEmitter();
  @Output() evtHeaderSearchChange: EventEmitter<any> = new EventEmitter();
  @Output() evtComposeClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() evtComposeUserSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() evtNewMessage: EventEmitter<any> = new EventEmitter<any>();
  @Output() evtUserMessageLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output() evtMessageMenu: EventEmitter<any> = new EventEmitter<any>();
  @Output() evtSongbookStep: EventEmitter<any> = new EventEmitter<any>();
  @Output() evtScrollTop: EventEmitter<any> = new EventEmitter<any>();
  @Output() evtSendSnapCredit: EventEmitter<any> = new EventEmitter<any>();
  @Output() evtSendGift: EventEmitter<any> = new EventEmitter<any>();
  @Output() evtShowBlockedNotify: EventEmitter<any> = new EventEmitter<any>();
  @Output() evtSocialShare: EventEmitter<any> = new EventEmitter<any>();


  //used to send info to main-menu for Store landing page
  private subjectName = new Subject<any>(); //need to create a subject
    sendUpdate(message: string) { //the component that wants to update something, calls this fn
      this.subjectName.next({ text: message }); //next() will feed the value in Subject
  }
    getUpdate(): Observable<any> { //the receiver component calls this function 
      return this.subjectName.asObservable(); //it returns as an observable to which the receiver funtion will subscribe
  }

  

  constructor() {}

  onSelectUser(user) {
    this.evtSelectUser.emit(user);
  }

  onUpdateUserProfile(user) {
    this.evtUpdateUserProfile.emit(user);
  }

  onShowContactList() {
    this.evtToggleContact.emit();
    this.onSelectUser(null);
  }

  onStartConversation(c) {
    this.evtStartConversation.emit(c);
  }

  onArchiveConversation(cId) {
    this.evtArchiveConversation.emit(cId);
  }

  onHeaderSearchChanged(query) {
    this.evtHeaderSearchChange.emit(query);
  }

  onComposeClick(value) {
    this.evtComposeClick.emit(value);
  }

  onComposeUserSelect() {
    this.evtComposeUserSelect.emit();
  }

  onNewMessage(msg) {
    this.evtNewMessage.emit(msg);
  }

  onUserMessageLoad(msg) {
    this.evtUserMessageLoad.emit(msg);
  }

  onMessageMenu(type, cId, msgId = null) {
    this.evtMessageMenu.emit({ type, cId, msgId });
  }

  onSongBookStep(step) {
    this.evtSongbookStep.emit({ step });
  }

  onScrollTop() {
    this.evtScrollTop.emit();
  }

  onSendSnapCredit(user) {
    this.evtSendSnapCredit.emit(user);
  }

  onSendGift(user) {
    this.evtSendGift.emit(user);
  }

  onShowBlockedNotify() {
    this.evtShowBlockedNotify.emit();
  }

  onSocialShare(data) {
    this.evtSocialShare.emit(data);
  }
}
