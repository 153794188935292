import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  NgZone,
} from '@angular/core';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {
  GenresService,
  UsersService,
  RecordingService,
  SongService,
  UtilService,
  ProfileService,
  EventService,
  HelperService,
  Data
} from '@app/core';

@Component({
  selector: 'app-see-more',
  templateUrl: './see-more.component.html',
  styleUrls: ['./see-more.component.scss']
})
export class SeeMoreComponent implements OnInit, OnDestroy {
  @ViewChild('playlist') private playlistElem;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  recording;
  //trendingRecordings$: any;
  recommendedRecordings$;
  //trendingRecordings = [];
  genreSortOptions = this.util.recordingSortOptions;
  @ViewChild('goldcontent') private goldModal;
  currentPage = 1;
  itemsPerPage = 30;
  pageTitle="";
  pageHeader="";
  getDataAPI:any ;
  showSeeMorePageType="";
  user: any;
  recordingSortOptions = this.util.recordingSortOptions;
  playlistSortOptions = this.util.playlistSortOptions;
  songSortOptions = this.util.songSortOptions;

  profileUserName="";
  paramsType: string = "";
  snappenins: any = [];

  favoriteData: any = {
    ...this.util.generateGridData(),
    fetch: this.profileService.getFavoriteRecordings.bind(this.profileService),
    sort: this.recordingSortOptions[0],
  };

  whatsSnappenin: any = {
    ...this.util.generateGridData(),
    fetch: this.recordingService.getSnappeninsV2.bind(this.recordingService),
    sort: this.recordingSortOptions[0],
  };

  trendingRecordings: any = {
    ...this.util.generateGridData(),
    fetch: this.recordingService.getTrendingRecordings.bind(this.recordingService),
    sort: this.recordingSortOptions[0],
  };//getRecentlyRecorded

  trendingSongs: any = {
    ...this.util.generateGridData(),
    fetch: this.songService.getTrendingSongs.bind(this.songService),
    sort: this.recordingSortOptions[0],
  };

  genresData: any = {
    ...this.util.generateGridData(),
    perPage: 20,
    sort: this.genreSortOptions[0],
    fetch: this.genreService.getGenres.bind(this.genreService),
  };

  // data = {
  //   song: {
  //     ...this.util.generateGridData(),
  //     sort: this.playlistSortOptions[0],
  //   },
  //   recording: {
  //     ...this.util.generateGridData(),
  //     sort: this.playlistSortOptions[0],
  //   },
  // };

  constructor(
    private recordingService: RecordingService,
    private songService: SongService,
    private genreService: GenresService,
    public profileService: ProfileService,
    private usersService: UsersService,
    private helperService: HelperService,
    private eventService: EventService,
    private toastService: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private util: UtilService,
    private ngZone: NgZone,
    private location: Location,
    private dataService: Data
  ) {
    this.recordingSortOptions = this.util.recordingSortOptions;
    this.favoriteData.sort = this.util.recordingSortOptions[0];

    this.route.params.subscribe(params => {
      this.paramsType = params.type;
    });
  }

  ngOnInit() {

      switch (this.paramsType)
      {
        case 'Snappenin':
          this.pageTitle = "What's Snappenin'";
          this.getDataAPI = this.whatsSnappenin;
        break;   
        case 'Trending-Recordings':
          this.pageTitle = "Trending Recordings";
          this.getDataAPI = this.trendingRecordings;
        break;    
        case 'Trending-Songs':
          this.pageTitle = "Trending Songs";
          this.getDataAPI = this.trendingSongs;
        break;
        case 'Genres':
          this.pageTitle = "Genres - Recordings";
          this.getDataAPI = this.genresData;
        break;
        case 'Genres-Songs':
          this.pageTitle = "Genres - Songs";
          this.getDataAPI = this.genresData;
        break;
        
      }

      this.showSeeMorePageType = this.paramsType;
 
      this.route.queryParams.subscribe(params => {
        if(typeof params.page != 'undefined') {
          this.currentPage = params.page
        }
      });
    
      this.LoadSongs(this.getDataAPI, null);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  checkMine(item){
    return item.favorited;
  }

  back(){
    window.history.back();
  }

  public navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }

  navListen(id){
    this.navigate([`/d/listen/${id}`]);
  }

  navEditRec(id){
    this.navigate([`/d/my-recordings/${id}`]);
  }

  navProfile(id){
    this.navigate([`/d/profile/${id}`]);
  }

  navArtists(id){
    this.navigate([`/d/artists/${id}`]);
  }

  navGenres(id){
    this.navigate([`/d/genres/${id}/recordings`]);
  }

  navGenresSongs(id){
    this.router.navigate([`/d/genres/${id}/songs`], {queryParams: { page: '1', genre: id }});
  }

  handleDuet(duet) {
    console.log(duet);
    if (duet.song.gold && !this.profileService.user.gold) {
      this.goldModal.open();
      return;
    }
    this.router.navigate(['/duet-recorder', duet.id]);
  }

  handleSing(song) {
    if (song.gold && !this.profileService.user.gold) {
      this.goldModal.open();
      return;
    }
    this.navigate([`/recorder/${song.id}`])
  }

  favRecording(recording) {
    this.recordingService.fav(recording.id).subscribe(response => {
      if (response.success) {
        recording.favorited = true;
        this.toastService.success('Recording was added to favorites.');
      } else {
        this.toastService.error('There was an error, try again later.');
      }
    });
  }

  unFavRecording(recording) {
    this.recordingService.unfav(recording.id).subscribe(response => {
      if (response.success) {
        recording.favorited = false;
        this.toastService.success('Recording was removed from favorites.');
      } else {
        this.toastService.error('There was an error, try again later.');
      }
    });
  }

  detectChanges() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  setPageParam() {
    this.location.replaceState(this.location.path().split('?')[0], '');
    this.location.replaceState(this.location.path() + `?page=${this.currentPage}`);
    // this.location.replaceState(this.location.path() + `&p=${this.showSeeMorePageType}`);
    // if(this.userIDfromProfieFavs > 0){
    //   this.location.replaceState(this.location.path() + `&u=${this.userIDfromProfieFavs}`);
    // }
  }

  LoadSongs(data, sort) {  
    if (!data.fetch) {
      return;
    }
    if (sort) {
      data.sort = sort;
    }
    
    if (!data.loading) {
      data.loading = true;
      this.detectChanges();
      data.fetch(this.currentPage, this.itemsPerPage, data.sort.field, data.sort.asc).subscribe(
        response => {
          data.currentPage =  this.currentPage;
          data.items = data.items.concat(response.data);
          data.lastPage = response.next_page_url;
          data.loading = false;
          this.setPageParam();
          this.detectChanges();
        },
        error => {
          this.toastService.error(error.message);
        }
      );
    }
  }

  getNextSongs() {
    let data = this.getDataAPI;
    if(typeof data.filter == 'undefined') {
      data.filter = {};
    }
    if(typeof data.filter == 'undefined') {
      data.decade = '';
    }

    if (!data.loading && data.lastPage) {
      data.loading = true;
      this.currentPage++;
      this.detectChanges();
      data.fetch(this.currentPage, this.itemsPerPage, data.sort.field, data.sort.asc).subscribe(
          response => {
            data.currentPage = this.currentPage;
            data.items = response.data;
            data.lastPage = response.next_page_url;
            data.loading = false;
            window.scrollTo(0, 0);
            this.setPageParam();
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }

  getPrevSongs() {
    let dataMyFav = this.getDataAPI;
    if(typeof dataMyFav.filter == 'undefined') {
      dataMyFav.filter = {};
    }
    if(typeof dataMyFav.filter == 'undefined') {
      dataMyFav.decade = '';
    }

    if (!dataMyFav.loading && dataMyFav.currentPage != 1) {
      dataMyFav.loading = true;
      this.currentPage--;
      this.detectChanges();
      dataMyFav.fetch(this.currentPage, this.itemsPerPage, dataMyFav.sort.field, dataMyFav.sort.asc).subscribe(
          response => {
            dataMyFav.currentPage = this.currentPage;
            dataMyFav.items = response.data;
            dataMyFav.lastPage = response.next_page_url;
            dataMyFav.loading = false;
            window.scrollTo(0, 0);
            this.setPageParam();
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }

 

  resetDataObj(data) {
    this.currentPage = 1;
    data.currentPage = 1;
    data.loading = false;
    data.items = [];
    data.lastPage = 1;
    return data;
  }


  onAddtoPlaylist(id) {
    this.playlistElem.open(id);
  }

  async subscribeUser(recording) {
    const recordingList = [
      this.favoriteData.items,
    ];
    this.helperService.subscribeUser(recording, recordingList);
  }

  async unsubscribeUser(recording) {
    const recordingList = [
      this.favoriteData.items,
    ];
    this.helperService.unsubscribeUser(recording, recordingList);
  }

  shareRecording(recording) {
    this.eventService.onSocialShare({ recording });
  }

  howOthersSingThis(id){
    this.navigate([`/d/ListenOthersSing/${id}`]);
  }
}

