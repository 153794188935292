import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { RecordingService, ProfileService, SongService } from '@app/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-my-recordings-recycle-bin',
  templateUrl: './my-recordings-recycle-bin.component.html',
  styleUrls: ['./my-recordings-recycle-bin.component.scss']
})
export class MyRecordingsRecycleBinComponent implements OnInit {
  recordings;
  recording: any;
  user: any;
  data = {
      items: [],
      rqing: false,
      sort: 'created_at',
      lastPage: 1,
      currentPage: 1,
      perPage: 20,
      fetch: null,
      total: 1

  };

  totalPages=1;
  currentPage=1;
  totalRecordingsForFilter='';
  totalRecordingsShowingForFilter= '';
  getRecycleBinRecordings = this.recordingService.getRecordingsFromRecycleBin.bind(this.recordingService);

  @ViewChild('playlist') private playlistElem;
  constructor(private recordingService: RecordingService,
      private activatedRoute: ActivatedRoute,
      private toastService: ToastrService,
      private songService: SongService,
      private profileService: ProfileService,
      private cdr: ChangeDetectorRef) {
      this.activatedRoute.params.subscribe(params => {
        this.recordings = null;
        this.user = null;
        this.data = {
            items: [],
            rqing: false,
            sort: 'created_at',
            lastPage: 1,
            currentPage: 1,
            perPage: 20,
            fetch: null,
            total: 1
            }
   
      });
  }

  onLoad() {
   if (this.profileService.user.id) {
    this.data.rqing = true;
    this.currentPage = 1;
    this.getRecycleBinRecordings(this.data.currentPage)
    .subscribe((response) => {
           this.data=response.data;
           this.recording = response;
           this.recordings = response.data;
        this.totalPages =  this.recording.last_page;

        this.totalRecordingsForFilter =  this.recording.total;

        this.showingRecordings(this.recording.from,this.recording.to);
        this.data.rqing = false;
        this.detectChanges();
        if (this.data.total === 0) {
            this.toastService.success('No results for your search.');
        }
        this.setTotalPages();
        
    });
    } error => {
        this.toastService.error(error.message);
    };

  }

  ngOnInit() {this.onLoad(); }

  restoreRecording(recording) {
    this.detectChanges();
      this.recordingService.restoreRecordingFromRecycleBin(recording.id).subscribe(response => {
        recording = response.model;
        this.detectChanges();
        this.onLoad();    
          if (response.success) {
              this.toastService.success('Recording was restored.');
          } else {
              this.toastService.error('There was an error restoring recording, try again later.');
          }
      });
   
      this.detectChanges();
      this.onLoad();
  }

  deleteRecording(recording) {
    this.detectChanges();
      this.recordingService.deleteRecordingFromRecycleBin(recording.id).subscribe(response => {
          recording = response.model;
          this.detectChanges();
          this.onLoad();
          if (response.success) {
              this.toastService.success('Recording was deleted from the recycle bin.');
          } else {
              this.toastService.error('There was an error deleting recording, try again later.');
          }
      });
      this.onLoad();
  }

  onScrollEndHandle(data, sort = 'created_at') {
      if (typeof sort !== 'object') {
          if (sort) {
              sort = this.cleanSort(sort);
              data.sort = sort;
          }
      }
   }

  resetDataObj(data) {
      data.currentPage = 1;
      data.rqing = false;
      data.items = [];
      return data;
  }

  cleanSort(sort) {
      if (typeof sort === 'string') {
          if (sort === 'popular') {
              return 'popularity';
          } else {
              return 'created_at';
          }
      } else {
          return 'created_at';
      }
  }

  detectChanges() {
      this.cdr.markForCheck();
      this.cdr.detectChanges();
  }

  setTotalPages(){
    return  this.totalPages;
    }

getLastPageFromURL(url){
    let pos = url.lastIndexOf("=");
    let result =  url.substr(pos + 1);
    return result;
  }

  showingRecordings(fromRecording,toRecording){
        
    if(fromRecording != null){
        this.totalRecordingsShowingForFilter = fromRecording +'-'+ toRecording +'  of ';

    }else{
        this.totalRecordingsShowingForFilter = ''; 
    }
}

getPrevRecordings(){
    if (!this.data.rqing ) {
        if(this.currentPage<=1){
            return;
        }
        this.currentPage--, scrollTo(0, 0);
        this.data.rqing = true;
        this.detectChanges();
        let obs = [];
        this.getRecycleBinRecordings(this.currentPage)
            .subscribe((response) => {
                this.data.currentPage = this.data.currentPage - 1;
                this.recordings = response.data;
                response.data = response.data.map(rec => {
                    return rec;
                });
                   this.data.items = response.data;
                this.data.lastPage = response.next_page_url;
                this.totalPages =  Number(this.getLastPageFromURL(response.last_page_url));
                this.totalRecordingsForFilter =  response.total;
                this.showingRecordings(response.from,response.to);
                this.data.rqing = false;
                this.detectChanges();
                if (this.data.total === 0) {
                    this.toastService.success('No results for your search.');
                }
            }, error => {
                this.toastService.error(error.message);
            });
            
    }
}

getNextRecordings(){
    if (!this.data.rqing &&this.totalPages) {
        if(this.currentPage==this.totalPages){
            return;
        }
        this.currentPage++, scrollTo(0, 0);
        this.data.rqing = true;
        this.detectChanges();
        let obs = [];
        this.getRecycleBinRecordings(this.currentPage)
            .subscribe((response) => {
                this.data.currentPage = this.data.currentPage + 1;
                this.recordings = response.data;
                response.data = response.data.map(rec => {
                    
                    return rec;
                });
                this.data.items = response.data;
                this.data.lastPage = response.next_page_url;
                this.totalPages =  Number(this.getLastPageFromURL(response.last_page_url));
                this.totalRecordingsForFilter =  response.total;
                this.showingRecordings(response.from,response.to);
                this.data.rqing = false;
                this.detectChanges();
                if (this.data.total === 0) {
                    this.toastService.success('No results for your search.');
                }
            }, error => {
                this.toastService.error(error.message);
            });
            
    }
}

}
